import { useCallback, useContext, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import productService from "../services/products";
import { UserContext } from "../App";
import { Img } from "react-image";

const CACHE = {} as any;
const PER_PAGE = 4;

async function makeAndHandleRequest(token: string, query: string, page = 1) {
  const products = await productService.getProducts(
    token,
    PER_PAGE,
    page,
    undefined,
    query
  );
  return { options: products.data, total_count: products.total };
}

function ProductSelect(props: any) {
  const { token } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([] as any[]);
  const [query, setQuery] = useState("");

  const handleInputChange = (q: string) => {
    setQuery(q);
  };

  const handlePagination = (e: any, shownResults: any) => {
    const cachedQuery: any = CACHE[query];

    // Don't make another request if:
    // - the cached results exceed the shown results
    // - we've already fetched all possible results
    if (
      cachedQuery.options.length > shownResults ||
      cachedQuery.options.length === cachedQuery.total_count
    ) {
      return;
    }

    setIsLoading(true);

    const page = cachedQuery.page + 1;

    makeAndHandleRequest(token, query, page).then((resp) => {
      const options = cachedQuery.options.concat(resp.options);
      CACHE[query] = { ...cachedQuery, options, page };

      setIsLoading(false);
      setOptions(options);
    });
  };

  // `handleInputChange` updates state and triggers a re-render, so
  // use `useCallback` to prevent the debounced search handler from
  // being cancelled.
  const handleSearch = useCallback(
    (q: string) => {
      if (CACHE[q]) {
        setOptions(CACHE[q].options);
        return;
      }

      setIsLoading(true);
      makeAndHandleRequest(token, q).then((resp) => {
        CACHE[q] = { ...resp, page: 1 };

        setIsLoading(false);
        setOptions(resp.options);
      });
    },
    [token]
  );

  const onChange = (selected: any) => {
    props.onChange && props.onChange(selected[0]);
  };

  return (
    <AsyncTypeahead
      id="async-pagination-example"
      isLoading={isLoading}
      labelKey={"name"}
      filterBy={["code", "name"]}
      maxResults={PER_PAGE - 1}
      minLength={2}
      onInputChange={handleInputChange}
      onPaginate={handlePagination}
      onSearch={handleSearch}
      onChange={onChange}
      options={options}
      paginate
      paginationText="Mostrar más resultados"
      placeholder="Buscar producto"
      renderMenuItemChildren={(option: any) => (
        <div key={option.id}>
          <Img
            alt={option.name}
            src={option.img || "/assets/img/sin-imagen.jpg"}
            style={{
              height: "50px",
              marginRight: "10px",
              width: "50px",
            }}
            loader={
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    display: "flex",
                    width: "60px",
                    height: "50px",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="spinner-border"
                    role="status"
                    style={{ marginRight: "10px" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
                <div>
                  <span>{`[${option.code}] ${option.name}`}</span>
                </div>
              </div>
            }
            container={(children) => {
              return (
                <div style={{ height: "50px" }}>
                  {children}
                  <span>{`[${option.code}] ${option.name}`}</span>
                </div>
              );
            }}
          />
        </div>
      )}
      useCache={false}
    />
  );
}

export default ProductSelect;
