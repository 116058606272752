import { UserContext } from "../App";
import { useContext, useEffect, useState } from "react";
import Banner from "./Banner";
import FeatureProduct from "./FeatureProduct";
import ScrollToTopOnMount from "../template/ScrollToTopOnMount";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import productService from "../services/products";

function Landing() {
  const [products, setProducts] = useState([] as any[]);
  const { site } = useContext(UserContext);

  const HOME_TEXT = site?.config?.homeText;
  const FACE = site?.config?.social?.facebook;
  const INSTA = site?.config?.social?.instagram;
  const WHATSAPP = site?.config?.social?.whatsapp;

  useEffect(() => {
    const getFeatureProducts = async () => {
      const products = await productService.getFeatureProducts();
      setProducts(products);
    };
    getFeatureProducts();
  }, []);

  return (
    <>
      <ScrollToTopOnMount />
      <Banner />
      <div id="home" className="d-flex flex-column bg-white py-4">
        <p className="text-center px-5">{HOME_TEXT}</p>
        <div className="d-flex justify-content-center">
          <Link to="/products" className="btn btn-outline-dark">
            Ver todos nuestros productos
          </Link>
        </div>
      </div>
      {products.length > 0 && (
        <>
          <h2 className="text-muted text-center mt-4 mb-3">
            Productos destacados
          </h2>
          <div id="features" className="container pb-5 px-lg-5">
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 px-md-5">
              {products.map((p: any, i: number) => (
                <FeatureProduct key={i} {...p} />
              ))}
            </div>
          </div>
        </>
      )}
      <div className="d-flex flex-column bg-white py-4">
        <h5 className="text-center mb-3">Nuestras redes</h5>
        <div className="d-flex justify-content-center">
          <a href={FACE} target={"_blank"} rel={"noreferrer"} className="me-3">
            <FontAwesomeIcon icon={["fab", "facebook"]} size="2x" />
          </a>
          <a href={INSTA} target={"_blank"} rel={"noreferrer"}>
            <FontAwesomeIcon icon={["fab", "instagram"]} size="2x" />
          </a>
          <a
            href={WHATSAPP}
            target={"_blank"}
            rel={"noreferrer"}
            className="ms-3"
          >
            <FontAwesomeIcon icon={["fab", "whatsapp"]} size="2x" />
          </a>
        </div>
      </div>
    </>
  );
}

export default Landing;
