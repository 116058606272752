import HtmlEditor from "./HtmlEditor";

function AboutPage() {
  return (
    <div className="m-4 mt-5 pt-5">
      <HtmlEditor configName={"aboutPage"} />
    </div>
  );
}

export default AboutPage;
