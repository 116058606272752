import "dotenv/config";
import { ApiListResponseDto } from "../dtos";

class ProductService {
  async getProducts(
    token: string,
    limit: number,
    page: number,
    order?: string,
    searched?: string,
    filter?: {
      category: any;
      brands: any[];
      types: any[];
      minPrice: number;
      maxPrice: number;
    }
  ): Promise<ApiListResponseDto> {
    const builderFilter = [];
    if (filter) {
      if (filter.category) {
        builderFilter.push(`{"category.id":${filter.category.id}}`);
      }
      if (filter.brands.length > 0) {
        builderFilter.push(
          `{"brand.id":{"$in":[${filter.brands.map((b) => b.id)}]}}`
        );
      }
      if (filter.types.length > 0) {
        builderFilter.push(
          `{"type.id":{"$in":[${filter.types.map((t) => t.id)}]}}`
        );
      }
      if (filter.minPrice) {
        builderFilter.push(`{"price":{"$gte":${filter.minPrice}}}`);
      }
      if (filter.maxPrice) {
        builderFilter.push(`{"price":{"$lte":${filter.maxPrice}}}`);
      }
    }

    if (searched) {
      builderFilter.push(
        `{"$or":[{"name":{"$contL":"${searched}"}},{"code":{"$contL":"${searched}"}}]}`
      );
    }
    const queryFilter = builderFilter.join(",");
    const search = queryFilter ? `&s={"$and":[${queryFilter}]}` : "";

    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_API_HOST
        }/products?limit=${limit}&page=${page}${
          order ? `&sort=${order}` : ""
        }${search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.json();
    } catch (error) {
      console.log(error);
      return new ApiListResponseDto();
    }
  }

  async getFeatureProducts(): Promise<any[]> {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/products/featured`
      );
      return response.json();
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  async getProduct(id: number): Promise<any> {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/products/${id}`
      );
      return response.json();
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async getRelatedProducts(id: number): Promise<any[]> {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/products/${id}/relaters`
      );
      return response.json();
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  async createProduct(token: string, product: any): Promise<any> {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/products`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(product),
        }
      );
      return response.json();
    } catch (error) {
      console.log(error);
      return {};
    }
  }

  async updateProduct(token: string, product: any): Promise<any> {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/products/${product.id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(product),
        }
      );
      return response.json();
    } catch (error) {
      console.log(error);
      return {};
    }
  }
}

const productService = new ProductService();
export default productService;
