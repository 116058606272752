import moment from "moment";
// import { Card, Row, Col } from "react-bootstrap"; //TODO: use bootstrap class

function OrderD(props: any) {
  const order = props.order;
  const preferenceId = order.preferenceId;
  const cart = order.cart;
  let subtotal = 0;
  let subtotalT = 0;
  let discount = 0;

  const renderStatus = (status: string) => {
    switch (status) {
      case "created":
        return <span>Todavía no se ha realizado el pago.</span>;
      case "transferred":
        return <span>Todavía no se ha confirmado la trasferencia.</span>;
      case "paid":
        return <span>Venta en el local.</span>;
      case "pending":
        return (
          <span>
            El usuario no ha concluido el proceso de pago (por ejemplo, al
            generar un pago por boleto, se concluirá en el momento en que el
            usuario realice el pago en el lugar seleccionado).
          </span>
        );
      case "approved":
        return <span>El pago ha sido aprobado y acreditado.</span>;
      case "authorized":
        return <span>El pago ha sido autorizado pero aún no capturado.</span>;
      case "in_process":
        return <span>El pago está en análisis.</span>;
      case "in_mediation":
        return <span>El usuario inició una disputa.</span>;
      case "rejected":
        return (
          <span>
            El pago fue rechazado (el usuario puede intentar pagar de nuevo).
          </span>
        );
      case "cancelled":
        return <span>El pago fue cancelado o venció.</span>;
      case "refunded":
        return <span>El pago fue devuelto al usuario.</span>;
      case "charged_back":
        return (
          <span>
            Se realizó un contracargo en la tarjeta de crédito del comprador.
          </span>
        );
      default:
        return <span>{status}</span>;
    }
  };

  return (
    <div className="row p-3">
      <div className={"col-10 border bg-custom-light"}>
        <h2 style={{ textAlign: "center" }}>
          Orden N° {order.id} -{" "}
          {moment(order.createdAt).format("DD/MM/YY hh:mm:ss")}
        </h2>
        {cart.length > 0 &&
          cart.map((product: any, i: number) => {
            subtotal += product.subtotal;
            subtotalT += product.subtotalT;
            discount += product.discount;
            return (
              <h5 className="card-title text-dark text-truncate mb-1" key={i}>
                <span className="ms-3 me-3 badge rounded-pill bg-dark">
                  {product.cant} x
                </span>
                {product.name || "Producto sin Nombre"}
              </h5>
            );
          })}
        <h4 style={{ textAlign: "right" }}>
          <b>Total:</b>
          {preferenceId ? (
            <>{` $${subtotalT.toFixed(2)}`}</>
          ) : (
            <>
              {discount > 0 && <del>{` $${subtotal.toFixed(2)}`}</del>}
              {` $${(subtotal - discount).toFixed(2)}`}
            </>
          )}
        </h4>
      </div>
      <div className="col-2 border bg-custom-light">
        <h3 className="text-center mb-3">Estado</h3>
        <div className="d-flex justify-content-center">
          {renderStatus(order.status)}
        </div>
      </div>
    </div>
  );
}

export default OrderD;
