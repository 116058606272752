import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../App";
import { useContext } from "react";
// import { Card, Row, Col } from "react-bootstrap"; //TODO: use bootstrap class

function ProductD(props: any) {
  const { removeToCart } = useContext(UserContext);
  const editable = props.editable;
  const price = props.price;
  const cardTaxPercentage = props.category.cardTaxPercentage / 100 || 0;

  let offPrice: any = price ? (
    <table style={{ textAlign: "justify" }}>
      <tbody>
        <tr>
          <th>EFECTIVO</th>
          <td>{`$ ${price.toFixed(2)}`}</td>
        </tr>
        <tr>
          <th>TARJETA</th>
          <td>${(price + price * cardTaxPercentage).toFixed(2)}</td>
        </tr>
      </tbody>
    </table>
  ) : (
    "Producto sin Precio"
  );

  if (price && props.percentOff && props.percentOff > 0) {
    offPrice = (
      <table style={{ textAlign: "justify" }}>
        <tbody>
          <tr>
            <th>EFECTIVO</th>
            <td>
              <del>$ {price.toFixed(2)}</del>
              {" $ "}
              {(price - (props.percentOff * price) / 100).toFixed(2)}
            </td>
          </tr>
          <tr>
            <th>TARJETA</th>
            <td>$ {(price + price * cardTaxPercentage).toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
    );
  }
  return (
    <div className="col">
      <div
        className={`card shadow-sm${
          props.stock === 0 ? " bg-custom-light" : ""
        }`}
      >
        <div className="row g-0">
          <div className="col-12">
            <div className="card-body h-100">
              <div className="mt-auto d-flex">
                <button
                  className="btn btn-outline-dark ms-auto"
                  onClick={() => editable && removeToCart(props, true)}
                  disabled={!editable}
                >
                  {<FontAwesomeIcon icon={["fas", "x"]} />}
                </button>
              </div>
              <div className="d-flex flex-column h-100">
                <h5 className="card-title text-dark text-truncate mb-1">
                  <span className="ms-3 me-3 badge rounded-pill bg-dark">
                    {props.cant} x
                  </span>
                  {props.name || "Producto sin Nombre"}
                </h5>
                <span className="card-text text-muted mb-2 flex-shrink-0">
                  {offPrice}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductD;
