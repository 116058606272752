import ProductC from "../products/ProductC";
import ProductD from "../products/ProductD";
import { useCallback, useContext, useEffect, useState } from "react";
import { UserContext } from "../App";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Checkout from "./Checkout";
import { toast } from "react-toastify";
import orderService from "../services/orders";
import TransferModal from "./TransferModal";
import HtmlEditor from "./HtmlEditor";

function Cart() {
  const { user, setUser, token, setToken, cart, setCart, manageMode } =
    useContext(UserContext);
  const [preferenceId, setPreferenceId] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [editable, setEditable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [URLSearchParams] = useSearchParams();
  const params = Object.fromEntries(URLSearchParams.entries());
  const navigate = useNavigate();

  const [total, setTotal] = useState(0);
  const [totalT, setTotalT] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [subtotalT, setSubtotalT] = useState(0);
  const [discount, setDiscount] = useState(0);

  const logout = useCallback(() => {
    localStorage.setItem("access_token", "");
    setToken("");
    setUser({});
    toast.error("Tu sesión ha expirado");
    navigate("/user/login");
  }, [setToken, setUser, navigate]);

  useEffect(() => {
    if (cart.length > 0) {
      let subtotal = 0;
      let subtotalT = 0;
      let discount = 0;
      cart.forEach((product: any) => {
        if (product.cant > 0) {
          subtotal += product.subtotal;
          subtotalT += product.subtotalT;
          discount += product.discount;
        }
      });
      setSubtotal(subtotal);
      setSubtotalT(subtotalT);
      setDiscount(discount);
      setTotal(subtotal - discount);
      setTotalT(subtotalT);
    }
  }, [cart]);

  useEffect(() => {
    const paymentResolver = (status: string) => {
      switch (status) {
        case "pending":
          toast.info(
            `El pago esta pendiente. Se concluirá en el momento en que realices el pago en el lugar seleccionado.`
          );
          setCart([]);
          navigate(`/my_purchases`);
          break;
        case "approved":
          toast.success(`El pago ha sido aprobado y acreditado.`);
          setCart([]);
          navigate(`/my_purchases`);
          break;
        case "authorized":
          toast.warn(`El pago ha sido autorizado pero aún no impacto.`);
          setCart([]);
          navigate(`/my_purchases`);
          break;
        case "in_process":
          toast.warning(`El pago está en análisis.`);
          break;
        case "in_mediation":
          toast.warning(`A iniciado una mediación.`);
          break;
        case "rejected":
          toast.error(`El pago fue rechazado (puede intentar de nuevo).`);
          break;
        case "cancelled":
          toast.error(`El pago fue cancelado o venció.`);
          break;
        case "refunded":
          toast.error(`El pago fue devuelto.`);
          break;
        case "charged_back":
          toast.error(`Se realizó un contracargo en la tarjeta de crédito.`);
          break;
        default:
          return <></>;
      }
    };

    const getOrder = async () => {
      const order = await orderService.getOrder(
        token,
        params.external_reference
      );
      if (order.statusCode === 401) {
        logout();
      } else {
        const items = order.cart || [];
        setCart(items);
        setPreferenceId(order.preferenceId);
      }
    };
    if (!preferenceId && token && params.external_reference) {
      getOrder();
    }
    if (preferenceId && preferenceId === params.preference_id) {
      paymentResolver(params.payment_status);
    }
  }, [navigate, logout, setCart, token, preferenceId, params]);

  const createOrder = async () => {
    const order = await orderService.createOrder(token, { cart });
    if (order.statusCode === 401) {
      logout();
    } else {
      setPreferenceId(order.preferenceId);
      const newCart = order.cart;
      if (JSON.stringify(cart) !== JSON.stringify(newCart)) {
        setCart(newCart);
        localStorage.setItem("cart", JSON.stringify(newCart));
        toast.warn(`Se actualizo el carrito de compras (stock y/o precios).`);
      }
      setIsLoading(false);
    }
  };

  const orderToTransferred = async () => {
    const order = await orderService.orderToTransferred(token, { cart });
    if (order.statusCode === 401) {
      logout();
    } else {
      const newCart = order.cart;
      if (JSON.stringify(cart) !== JSON.stringify(newCart)) {
        setCart(newCart);
        localStorage.setItem("cart", JSON.stringify(newCart));
        toast.warn(`Se actualizo el carrito de compras (stock y/o precios).`);
      } else {
        setCart([]);
        navigate(`/my_purchases`);
      }
      setIsLoading(false);
    }
  };

  if (manageMode) {
    return (
      <div className="container mt-5 py-5 px-xl-5">
        <h2 style={{ textAlign: "center" }}>
          Instrucciones para trasferencias
        </h2>
        <HtmlEditor configName={"transferInstructions"} />
      </div>
    );
  }

  if (cart.length === 0) {
    return (
      <div className="container mt-5 py-4 px-xl-5">
        <div className="d-flex justify-content-center">
          <img
            className="img-fluid bg-dark cover"
            alt={"carrito vacio"}
            src={"assets/img/carrito-vacio.jpg"}
          />
        </div>
        <div className="d-flex justify-content-center mt-5">
          <Link to="/products" className="btn btn-outline-dark">
            Ir a la Tienda
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-5 py-4 px-xl-5">
      <div className="row mb-3">
        <div className={"col-md-8"}>
          <h2 style={{ textAlign: "center" }}>Carrito de Compras</h2>
          <div
            className={
              "row row-cols-1 row-cols-md-2 row-cols-lg-2 g-3 mb-4 flex-shrink-0 row-cols-xl-1"
            }
          >
            {cart.length > 0 &&
              cart.map((p: any, i: number) => (
                <ProductC key={i} editable={editable} {...p} />
              ))}
          </div>
        </div>
        <div className={"col-md-4"}>
          <div>
            <h2 style={{ textAlign: "center" }}>Detalle</h2>
            {cart.length > 0 &&
              cart.map((p: any, i: number) => (
                <ProductD key={i} editable={editable} {...p} />
              ))}
          </div>
          <div className="pt-2">
            <select
              className="form-select"
              aria-label="Default select example"
              value={paymentMethod}
              onChange={(e: any) => {
                setPaymentMethod(e.target.value);
              }}
            >
              <option value="cash">Efectivo</option>
              <option value="card">Tarjeta</option>
            </select>
          </div>
          {paymentMethod === "cash" ? (
            <div className="mt-3">
              <h4 style={{ textAlign: "right" }}>
                Subtotal: ${subtotal.toFixed(2)}
              </h4>
              <h4 style={{ textAlign: "right" }}>
                Descuento: ${discount.toFixed(2)}
              </h4>
              <h2 style={{ textAlign: "right" }}>TOTAL: ${total.toFixed(2)}</h2>
            </div>
          ) : (
            <div className="mt-3">
              <h4 style={{ textAlign: "right" }}>
                Subtotal: ${subtotalT.toFixed(2)}
              </h4>
              <h2 style={{ textAlign: "right" }}>
                TOTAL: ${totalT.toFixed(2)}
              </h2>
            </div>
          )}
          <div className="mt-3">
            <div className="col">
              {!user.id ? (
                <div className="d-flex justify-content-center">
                  <Link to="/user/login">
                    <input
                      className="btn btn-outline-dark mt-2"
                      value={"I N I C I A R   S E S I Ó N"}
                      readOnly
                    />
                  </Link>
                </div>
              ) : paymentMethod === "cash" ? (
                <TransferModal
                  onConfirm={() => {
                    setIsLoading(true);
                    setEditable(false);
                    orderToTransferred();
                  }}
                />
              ) : !preferenceId ? (
                <div className="d-flex justify-content-center">
                  <input
                    className="btn btn-outline-dark mt-2"
                    style={{ width: "-webkit-fill-available" }}
                    value={"C O N T I N U A R   C O M P R A"}
                    readOnly
                    disabled={isLoading}
                    onClick={() => {
                      setIsLoading(true);
                      setEditable(false);
                      createOrder();
                    }}
                  />
                </div>
              ) : (
                <Checkout preferenceId={preferenceId}></Checkout>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cart;
