import { Carousel, CarouselItem } from "react-bootstrap";
import CarouselCaption from "react-bootstrap/CarouselCaption";
import fileService from "../services/files";
import { UserContext } from "../App";
import { useContext, useState } from "react";
import { Img } from "react-image";
import { toast } from "react-toastify";
import siteService from "../services/site";

function RenderFileUpload(id: number) {
  const { token, manageMode } = useContext(UserContext);
  const [fileToUpload, setFileToUpload] = useState(null as File | null);

  if (!manageMode) {
    return <></>;
  }

  const uploadFile = async (id: number) => {
    if (fileToUpload) {
      const response = await fileService.updateBanner(token, id, fileToUpload);
      if (response.length === 0) {
        toast.warn("Archivo invalido.");
      } else {
        window.location.reload();
      }
    }
  };
  return (
    <div style={{ marginRight: "auto" }}>
      <input
        className="btn btn-outline-dark py-2"
        style={{ marginRight: "10px" }}
        type="file"
        id="formFileMultiple"
        onChange={(e) => {
          const files = (e.target.files && Array.from(e.target.files)) || [];
          setFileToUpload(files[0]);
        }}
      ></input>
      <button
        className="btn btn-outline-dark py-2 p-2"
        disabled={!fileToUpload}
        onClick={() => {
          let element: any =
            document && document.getElementById("formFileMultiple");
          element.value = "";
          element.files = null;
          setFileToUpload(null);
          uploadFile(id);
        }}
      >
        Cargar
      </button>
    </div>
  );
}

function Banner() {
  const { token, manageMode, site } = useContext(UserContext);
  const CAROUSEL = site?.config?.carousel;

  const updateSite = async () => {
    site.config.carousel = CAROUSEL;
    const response = await siteService.updateSite(token, site);
    if (!response) {
      toast.warn("No se pudo guardar.");
    } else {
      sessionStorage.setItem("site", JSON.stringify(site));
      window.location.reload();
    }
  };

  return (
    <Carousel interval={manageMode ? null : 3000}>
      <CarouselItem style={{ height: "500px" }}>
        <Img
          className="d-block w-100 h-100 bg-dark cover"
          alt=""
          src={`${process.env.REACT_APP_API_HOST}/banner/0`}
        />
        <CarouselCaption>
          <h5>
            {!manageMode ? (
              CAROUSEL &&
              CAROUSEL[0].title && (
                <span
                  className="ps-2 pe-2"
                  style={{ background: "rgba(0,0,0,0.5)" }}
                >
                  {CAROUSEL[0].title}
                </span>
              )
            ) : (
              <div>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Titulo"
                    defaultValue={CAROUSEL[0].title}
                    onChange={(e) => {
                      const value = e.target.value;
                      CAROUSEL[0].title = value;
                    }}
                  />
                  <button
                    className="btn btn-outline-dark"
                    type="submit"
                    onClick={updateSite}
                  >
                    Guardar
                  </button>
                </div>
              </div>
            )}
          </h5>
          {!manageMode ? (
            CAROUSEL &&
            CAROUSEL[0].description && (
              <p>
                <span
                  className="ps-2 pe-2"
                  style={{ background: "rgba(0,0,0,0.5)" }}
                >
                  {CAROUSEL[0].description}
                </span>
              </p>
            )
          ) : (
            <div>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Description"
                  defaultValue={CAROUSEL[0].description}
                  onChange={(e) => {
                    const value = e.target.value;
                    CAROUSEL[0].description = value;
                  }}
                />
                <button
                  className="btn btn-outline-dark"
                  type="submit"
                  onClick={updateSite}
                >
                  Guardar
                </button>
              </div>
            </div>
          )}
          {RenderFileUpload(0)}
        </CarouselCaption>
      </CarouselItem>
      <CarouselItem style={{ height: "500px" }}>
        <Img
          className="d-block w-100 h-100 bg-dark cover"
          alt=""
          src={`${process.env.REACT_APP_API_HOST}/banner/1`}
        />
        <CarouselCaption>
          <h5>
            {!manageMode ? (
              CAROUSEL &&
              CAROUSEL[1].title && (
                <span
                  className="ps-2 pe-2"
                  style={{ background: "rgba(0,0,0,0.5)" }}
                >
                  {CAROUSEL[1].title}
                </span>
              )
            ) : (
              <div>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Titulo"
                    defaultValue={CAROUSEL[1].title}
                    onChange={(e) => {
                      const value = e.target.value;
                      CAROUSEL[1].title = value;
                    }}
                  />
                  <button
                    className="btn btn-outline-dark"
                    type="submit"
                    onClick={updateSite}
                  >
                    Guardar
                  </button>
                </div>
              </div>
            )}
          </h5>
          {!manageMode ? (
            CAROUSEL &&
            CAROUSEL[1].description && (
              <p>
                <span
                  className="ps-2 pe-2"
                  style={{ background: "rgba(0,0,0,0.5)" }}
                >
                  {CAROUSEL[1].description}
                </span>
              </p>
            )
          ) : (
            <div>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Description"
                  defaultValue={CAROUSEL[1].description}
                  onChange={(e) => {
                    const value = e.target.value;
                    CAROUSEL[1].description = value;
                  }}
                />
                <button
                  className="btn btn-outline-dark"
                  type="submit"
                  onClick={() => {
                    updateSite();
                  }}
                >
                  Guardar
                </button>
              </div>
            </div>
          )}
          {RenderFileUpload(1)}
        </CarouselCaption>
      </CarouselItem>
      <CarouselItem style={{ height: "500px" }}>
        <Img
          className="d-block w-100 h-100 bg-dark cover"
          alt=""
          src={`${process.env.REACT_APP_API_HOST}/banner/2`}
        />
        <CarouselCaption>
          <h5>
            {!manageMode ? (
              CAROUSEL &&
              CAROUSEL[2].title && (
                <span
                  className="ps-2 pe-2"
                  style={{ background: "rgba(0,0,0,0.5)" }}
                >
                  {CAROUSEL[2].title}
                </span>
              )
            ) : (
              <div>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Titulo"
                    defaultValue={CAROUSEL[2].title}
                    onChange={(e) => {
                      const value = e.target.value;
                      CAROUSEL[2].title = value;
                    }}
                  />
                  <button
                    className="btn btn-outline-dark"
                    type="submit"
                    onClick={() => {
                      updateSite();
                    }}
                  >
                    Guardar
                  </button>
                </div>
              </div>
            )}
          </h5>
          {!manageMode ? (
            CAROUSEL &&
            CAROUSEL[2].description && (
              <p>
                <span
                  className="ps-2 pe-2"
                  style={{ background: "rgba(0,0,0,0.5)" }}
                >
                  {CAROUSEL[2].description}
                </span>
              </p>
            )
          ) : (
            <div>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Description"
                  defaultValue={CAROUSEL[2].description}
                  onChange={(e) => {
                    const value = e.target.value;
                    CAROUSEL[2].description = value;
                  }}
                />
                <button
                  className="btn btn-outline-dark"
                  type="submit"
                  onClick={() => {
                    updateSite();
                  }}
                >
                  Guardar
                </button>
              </div>
            </div>
          )}
          {RenderFileUpload(2)}
        </CarouselCaption>
      </CarouselItem>
    </Carousel>
  );
}

export default Banner;
