import { Link } from "react-router-dom";
import { Img } from "react-image";
// import { Card, Col } from "react-bootstrap"; //TODO: use bootstrap class

function FeatureProduct(props: any) {
  const price = props.price;
  let percentOff;
  let offPrice: any = price ? `$ ${price.toFixed(2)}` : "Producto sin Precio";

  if (props.percentOff && props.percentOff > 0) {
    percentOff = (
      <div
        className="badge bg-dim py-2 text-white position-absolute"
        style={{ top: "0.5rem", right: "0.5rem" }}
      >
        {props.percentOff}% Descuento
      </div>
    );

    offPrice = (
      <>
        <del>$ {price.toFixed(2)}</del>
        {" $ "}
        {(price - (props.percentOff * price) / 100).toFixed(2)}
      </>
    );
  }

  return (
    <div className="col">
      <div
        className={`card shadow-sm${
          props.stock === 0 ? " bg-custom-light" : ""
        }`}
      >
        <Link to={`/products/${props.id}`}>
          {percentOff}
          <Img
            className="card-img-top bg-dark cover"
            height="240"
            alt={props.name}
            src={props.img || "/assets/img/sin-imagen.jpg"}
            loader={
              <div style={{ height: "240px" }}>
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            }
          />
        </Link>
        <div className="card-body">
          <h5 className="card-title text-center">
            {props.name || "Producto sin Nombre"}
          </h5>
          <p className="card-text text-center text-muted">{offPrice}</p>
          <div className="d-grid gap-2"></div>
        </div>
      </div>
    </div>
  );
}

export default FeatureProduct;
