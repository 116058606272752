import "dotenv/config";

class UserService {
  async register(user: any): Promise<any> {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/users/register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user),
        }
      );
      return response.json();
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async login(email: string, password: string): Promise<any> {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/users/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        }
      );
      return response.json();
    } catch (error) {
      console.log(error);
      return error;
    }
  }
}

const userService = new UserService();
export default userService;
