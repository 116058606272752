import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useContext } from "react";
import { UserContext } from "../App";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import userService from "../services/users";
import { toast } from "react-toastify";

function Login() {
  const { user, setUser, setToken } = useContext(UserContext);
  const navigate = useNavigate();

  // React Params
  const params = useParams();

  // React States
  const [errorMessages, setErrorMessages] = useState({} as any);
  const [showPass, setShowPass] = useState(false);

  const logout = () => {
    localStorage.setItem("access_token", "");
    setToken("");
    setUser({});
  };

  const handleSubmitLogin = async (event: any) => {
    //Prevent page reload
    event.preventDefault();

    var { uname, pass } = document.forms[0];

    // Find user login info
    const login = await userService.login(uname.value, pass.value);

    // Compare user info
    if (login.error) {
      if (login.error === "password") {
        // Invalid password
        setErrorMessages({ name: "pass", message: login.message });
        logout();
      }
      if (login.error === "email") {
        // Username not found
        setErrorMessages({ name: "uname", message: login.message });
        logout();
      }
    } else {
      setUser(login.user);
      localStorage.setItem("access_token", login.access_token);
      setToken(login.access_token);
    }
  };

  const handleSubmitRegister = async (event: any) => {
    //Prevent page reload
    event.preventDefault();

    const { uname, lastname, email, phone, pass, pass2 } = document.forms[0];
    const payload = {
      name: uname.value,
      lastname: lastname.value,
      email: email.value,
      phone: phone.value,
      pass: pass.value,
      pass2: pass2.value,
    };

    const register = await userService.register(payload);
    if (register.error) {
      toast.error(register.message);
    } else {
      toast.success(`Usuario creado con éxito.`);
      navigate("/user/login");
    }
  };

  const handleSubmitReset = async (event: any) => {
    //Prevent page reload
    event.preventDefault();

    // const { email } = document.forms[0];
    // const payload = {
    //   email: email.value,
    // };
    toast.info(
      `En construcción, contacta con un administrador para recuperar tu acceso.`
    );
  };

  const showHidePass = () => {
    setShowPass(!showPass);
  };

  // Generate JSX code for error message
  const renderErrorMessage = (name: any) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  // JSX code for login form
  const loginForm = (
    <div className="form">
      <h3 style={{ textAlign: "center" }}>Iniciar Sesión</h3>
      <form onSubmit={handleSubmitLogin}>
        <div className="mt-2 mb-2">
          <label>Email</label>
          <input
            className="form-control mt-2"
            type="text"
            name="uname"
            maxLength={35}
            required
          />
          {renderErrorMessage("uname")}
        </div>
        <div className="mt-2 mb-2">
          <label>Contraseña</label>
          <div className="input-wrapper">
            <input
              className="form-control mt-2"
              type={showPass ? "text" : "password"}
              name="pass"
              maxLength={14}
              required
            />
            <div onClick={showHidePass}>
              <FontAwesomeIcon
                className="input-icon"
                icon={["fas", "glasses"]}
                size="2x"
              />
              <FontAwesomeIcon
                className="input-icon"
                icon={["fas", "slash"]}
                size="2x"
                opacity={showPass ? 0 : 1}
              />
            </div>
          </div>
          {renderErrorMessage("pass")}
        </div>
        <div className="d-flex justify-content-end">
          <Link to="/user/reset" className="mt-2">
            ¿Olvidaste tu contraseña?
          </Link>
        </div>
        <div className="d-flex justify-content-center">
          <input
            className="btn btn-outline-dark mt-2"
            type="submit"
            value={"I N I C I A R   S E S I Ó N"}
          />
        </div>
        <div className="d-flex justify-content-center mt-2">
          <p>
            ¿No tenés cuenta aún?{" "}
            <Link to="/user/register">
              <b>Crear cuenta</b>
            </Link>
          </p>
        </div>
      </form>
    </div>
  );

  // JSX code for register form
  const registerForm = (
    <div className="form">
      <h3 style={{ textAlign: "center" }}>Crear cuenta</h3>
      <span>Comprá más rápido y llevá el control de tus pedidos!</span>
      <form onSubmit={handleSubmitRegister}>
        <div className="mt-2 mb-2">
          <label>Nombre</label>
          <input
            className="form-control mt-2"
            type="text"
            name="uname"
            maxLength={14}
            required
          />
        </div>
        <div className="mt-2 mb-2">
          <label>Apellido</label>
          <input
            className="form-control mt-2"
            type="text"
            name="lastname"
            maxLength={14}
            required
          />
        </div>
        <div className="mt-2 mb-2">
          <label>Email</label>
          <input
            className="form-control mt-2"
            type="email"
            name="email"
            maxLength={35}
            required
          />
        </div>
        <div className="mt-2 mb-2">
          <label>Teléfono (10 números, sin 0 y sin 15)</label>
          <input
            className="form-control mt-2"
            type="tel"
            pattern="[0-9]{10}"
            name="phone"
            maxLength={10}
            required
          />
        </div>
        <div className="mt-2 mb-2">
          <label>Contraseña</label>
          <div className="input-wrapper">
            <input
              className="form-control mt-2"
              type={showPass ? "text" : "password"}
              name="pass"
              maxLength={14}
              required
            />
            <div onClick={showHidePass}>
              <FontAwesomeIcon
                className="input-icon"
                icon={["fas", "glasses"]}
                size="2x"
              />
              <FontAwesomeIcon
                className="input-icon"
                icon={["fas", "slash"]}
                size="2x"
                opacity={showPass ? 0 : 1}
              />
            </div>
          </div>
        </div>
        <div className="mt-2 mb-2">
          <label>Confirmar Contraseña</label>
          <div className="input-wrapper">
            <input
              className="form-control mt-2"
              type={showPass ? "text" : "password"}
              name="pass2"
              maxLength={14}
              required
            />
            <div onClick={showHidePass}>
              <FontAwesomeIcon
                className="input-icon"
                icon={["fas", "glasses"]}
                size="2x"
              />
              <FontAwesomeIcon
                className="input-icon"
                icon={["fas", "slash"]}
                size="2x"
                opacity={showPass ? 0 : 1}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <input
            className="btn btn-outline-dark mt-2"
            type="submit"
            value={"C R E A R   C U E N T A"}
          />
        </div>
        <div className="d-flex justify-content-center mt-2">
          <p>
            ¿Ya tenés una cuenta?{" "}
            <Link to="/user/login">
              <b>Iniciá sesión</b>
            </Link>
          </p>
        </div>
      </form>
    </div>
  );

  // JSX code for reset form
  const resetForm = (
    <div className="form">
      <h3 style={{ textAlign: "center" }}>Cambiar Contraseña</h3>
      <span>
        Vamos a enviarte un email para que puedas cambiar tu contraseña.
      </span>
      <form onSubmit={handleSubmitReset}>
        <div className="mt-2 mb-2">
          <label>Email</label>
          <input
            className="form-control mt-2"
            type="email"
            name="email"
            maxLength={35}
            required
          />
        </div>
        <div className="d-flex justify-content-center">
          <input
            className="btn btn-outline-dark mt-2"
            type="submit"
            value={"E N V I A R   E M A I L"}
          />
        </div>
        <div className="d-flex justify-content-center mt-2">
          <p>
            ¿Ya tenés la clave?{" "}
            <Link to="/user/login">
              <b>Iniciá sesión</b>
            </Link>
          </p>
        </div>
      </form>
    </div>
  );

  const renderFrom = () => {
    if (user.id) {
      return <Navigate to="/products" />;
    }
    switch (params.mode) {
      case "login":
        return loginForm;
      case "register":
        return registerForm;
      case "reset":
        return resetForm;
    }
  };

  return (
    <div className="center-form">
      <div className="margin-form">{renderFrom()}</div>
    </div>
  );
}

export default Login;
