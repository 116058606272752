import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ProductS from "../products/ProductS";
import ProductSelect from "./ProductSelect";

function SaleModal(props: any) {
  const [cart, setCart] = useState([] as any[]);
  const [modalShow, setModalShow] = useState(false);
  const [advancedMode, setAdvancedMode] = useState(false);
  const [type, setType] = useState("discount");
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0.0);
  const [total, setTotal] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [discount, setDiscount] = useState(0);

  useEffect(() => {
    if (cart.length > 0) {
      let subtotal = 0;
      let discount = 0;
      cart.forEach((product: any) => {
        if (product.cant > 0) {
          subtotal += product.subtotal;
          discount += product.discount * product.cant;
        }
      });
      setSubtotal(subtotal);
      setDiscount(discount);
      setTotal(subtotal - discount);
    }
  }, [cart]);

  const clear = () => {
    setCart([]);
    setAdvancedMode(false);
    setDescription("");
    setType("discount");
    setPrice(0);
  };

  const onConfirm = () => {
    clear();
    props.onConfirm && props.onConfirm(cart, paymentMethod);
  };

  const onCancel = () => {
    clear();
    props.onCancel && props.onCancel();
  };

  function addToCart(product: any) {
    const searched = cart.find((p) => p.id === product.id) || {
      ...product,
      cant: 0,
    };
    const cant =
      searched.cant >= product.stock ? product.stock : searched.cant + 1;
    const newCart = [
      ...cart.filter((p) => p.id !== product.id),
      {
        ...searched,
        cant,
        subtotal:
          product.id === +product.id || !product.discount
            ? product.price * (searched.cant + 1)
            : 0,
        discount:
          product.id === +product.id
            ? (product.percentOff / 100) * product.price
            : product.discount,
      },
    ];
    setCart(newCart.sort((a, b) => a.id - b.id));
  }

  function removeToCart(product: any, remove = false) {
    const searched = cart.find((p) => p.id === product.id);
    let newCart = cart.filter((p) => p.id !== product.id);
    if (searched.cant - 1 > 0 && !remove) {
      newCart.push({
        ...searched,
        cant: searched.cant - 1,
        subtotal:
          product.id === +product.id || !product.discount
            ? product.price * (searched.cant - 1)
            : 0,
        discount:
          product.id === +product.id
            ? (product.percentOff / 100) * product.price
            : product.discount,
      });
    }
    setCart(newCart.sort((a, b) => a.id - b.id));
  }

  const onProductSelect = (selected: any) => {
    selected && addToCart(selected);
  };

  const modal = (
    <Modal
      show={modalShow}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Nueva venta
        </Modal.Title>
        <div>
          <button
            className="btn btn-outline-dark py-2 w-100"
            title={advancedMode ? "Regresar" : "Modo Avanzado"}
            onClick={() => setAdvancedMode(!advancedMode)}
          >
            <FontAwesomeIcon icon={["fas", "right-left"]} size="1x" />{" "}
            {advancedMode ? "Regresar" : "Modo Avanzado"}
          </button>
        </div>
      </Modal.Header>
      <Modal.Body>
        {advancedMode ? (
          <div className="input-group">
            <div className="col-lg-3 d-lg-block">
              <select
                className="form-select"
                aria-label="Default select example"
                value={type}
                onChange={(e: any) => {
                  setType(e.target.value);
                }}
              >
                <option value="discount">Descuento</option>
                <option value="recharge">Recargo</option>3
              </select>
              <br className="d-lg-none" />
            </div>
            <input
              className="form-control"
              type="text"
              placeholder="Descripción"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
            <input
              className="form-control"
              type="number"
              step="1000"
              placeholder="Valor"
              value={price}
              onChange={(e) => {
                setPrice(+(+e.target.value).toFixed(2));
              }}
            />
            <button
              className="btn btn-outline-dark"
              type="submit"
              onClick={() => {
                const newItem = {
                  id: (Math.random() + 1).toString(36).substring(8),
                  price,
                  cant: 1,
                  name: description
                    ? description
                    : type === "discount"
                    ? "Descuento"
                    : "Recargo",
                  subtotal: type === "discount" ? 0 : price,
                  discount: type === "discount" ? price : 0,
                };
                setCart([...cart, newItem]);
              }}
            >
              Agregar
            </button>
          </div>
        ) : (
          <ProductSelect onChange={onProductSelect} />
        )}
        {cart.length > 0 && (
          <div className="container mt-5 py-4 px-xl-5">
            <div className="row mb-3">
              <div className={"col-md-8"}>
                <h2 style={{ textAlign: "center" }}>Productos</h2>
                <div
                  className={
                    "row row-cols-1 row-cols-md-2 row-cols-lg-2 g-3 mb-4 flex-shrink-0 row-cols-xl-1"
                  }
                >
                  {cart.map((p: any, i: number) => (
                    <ProductS
                      key={i}
                      addToCart={addToCart}
                      removeToCart={removeToCart}
                      editable
                      {...p}
                    />
                  ))}
                </div>
              </div>
              <div className={"col-md-4"}>
                <h2 style={{ textAlign: "center" }}>Detalle</h2>
                <div className="mt-3">
                  <h4 style={{ textAlign: "right" }}>
                    Subtotal: ${subtotal.toFixed(2)}
                  </h4>
                  <h4 style={{ textAlign: "right" }}>
                    Descuento: ${discount.toFixed(2)}
                  </h4>
                  <h2 style={{ textAlign: "right" }}>
                    TOTAL: ${total.toFixed(2)}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="py-2">
          <select
            className="form-select"
            aria-label="Default select example"
            value={paymentMethod}
            onChange={(e: any) => {
              setPaymentMethod(e.target.value);
            }}
          >
            <option value="cash">Efectivo</option>
            <option value="transferred">Transferencia</option>
            <option value="card">Tarjeta</option>
          </select>
        </div>

        <button
          className="btn btn-outline-dark py-2"
          onClick={() => {
            onConfirm();
            setModalShow(false);
          }}
          disabled={cart.length === 0}
        >
          Guardar
        </button>

        <button
          className="btn btn-outline-dark py-2"
          onClick={() => {
            onCancel();
            setModalShow(false);
          }}
        >
          Cancelar
        </button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <div>
      <button
        className="btn btn-outline-dark py-2 w-100"
        title="Nueva venta"
        onClick={() => setModalShow(true)}
      >
        <FontAwesomeIcon icon={["fas", "shop"]} size="2x" /> Nueva venta
      </button>

      {modal}
    </div>
  );
}

export default SaleModal;
