import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useState } from "react";
import { UserContext } from "../App";
import { useContext } from "react";
// import { Navbar} from "react-bootstrap"
const STORE_NAME = process.env.REACT_APP_STORE_NAME || "NombreTienda";

function Header() {
  const { cart, user, setUser, manageMode, setManageMode, setToken } =
    useContext(UserContext);
  const [openedDrawer, setOpenedDrawer] = useState(false);

  function toggleDrawer() {
    setOpenedDrawer(!openedDrawer);
  }

  function changeNav() {
    if (openedDrawer) {
      setOpenedDrawer(false);
    }
  }

  function logout() {
    localStorage.setItem("access_token", "");
    setToken("");
    setUser({});
    changeNav();
  }

  return (
    <header>
      <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-white border-bottom">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/" onClick={changeNav}>
            <FontAwesomeIcon icon={["fas", "tag"]} className="ms-1" size="lg" />
            <span className="ms-2 h5">{STORE_NAME}</span>
          </Link>

          <div
            className={
              "navbar-collapse offcanvas-collapse " +
              (openedDrawer ? "open" : "")
            }
          >
            <ul className="navbar-nav me-auto mb-lg-0">
              <li className="nav-item">
                <Link to="/products" className="nav-link" onClick={changeNav}>
                  Tienda
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/about" className="nav-link" onClick={changeNav}>
                  Sobre Nosotros
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/return-policy"
                  className="nav-link"
                  onClick={changeNav}
                >
                  Políticas de devolución
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/faqs" className="nav-link" onClick={changeNav}>
                  Preguntas Frecuentes
                </Link>
              </li>
            </ul>
            {user.isAdmin && (
              <div className="button-edit float-end p-2 mb-0">
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-outline-dark"
                    onClick={() => setManageMode(!manageMode)}
                  >
                    {!manageMode ? "Administrar" : "Salir del Editor"}
                  </button>
                </div>
              </div>
            )}
            <button
              type="button"
              className="btn btn-outline-dark me-3 d-none d-lg-inline"
            >
              <Link to="/cart" className="nav-link" onClick={changeNav}>
                <FontAwesomeIcon icon={["fas", "shopping-cart"]} />
                <span className="ms-3 badge rounded-pill bg-dark">
                  {cart.reduce(
                    (accumulator: number, product: any) =>
                      accumulator + product.cant,
                    0
                  )}
                </span>
              </Link>
            </button>
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item dropdown">
                <a
                  href="!#"
                  className="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  id="userDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon icon={["fas", "user-alt"]} />
                </a>
                {user.id ? (
                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="userDropdown"
                  >
                    <li className="dropdown-item">
                      <i>{user.email}</i>
                    </li>
                    <hr></hr>
                    {user.isAdmin && (
                      <>
                        <li>
                          <Link
                            to={`${process.env.REACT_APP_API_HOST}/admin/`}
                            className="dropdown-item text-danger"
                            target="_blank"
                          >
                            Administración
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/stock"
                            className="dropdown-item text-danger"
                          >
                            Stock
                          </Link>
                        </li>
                      </>
                    )}
                    <li>
                      <Link to="/my_purchases" className="dropdown-item">
                        Mis Compras
                      </Link>
                    </li>

                    <hr></hr>
                    <li>
                      <Link
                        to="/user/login"
                        className="dropdown-item"
                        onClick={logout}
                      >
                        Cerrar Sesión
                      </Link>
                    </li>
                  </ul>
                ) : (
                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="userDropdown"
                  >
                    <li>
                      <Link
                        to="/user/login"
                        className="dropdown-item"
                        onClick={changeNav}
                      >
                        Iniciar Sesión
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/user/register"
                        className="dropdown-item"
                        onClick={changeNav}
                      >
                        Registrarse
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
            </ul>
          </div>

          <div className="d-inline-block d-lg-none">
            <button type="button" className="btn btn-outline-dark">
              <Link to="/cart" className="nav-link" onClick={changeNav}>
                <FontAwesomeIcon icon={["fas", "shopping-cart"]} />
                <span className="ms-3 badge rounded-pill bg-dark">
                  {cart.reduce(
                    (accumulator: number, product: any) =>
                      accumulator + product.cant,
                    0
                  )}
                </span>
              </Link>
            </button>
            <button
              className="navbar-toggler p-0 border-0 ms-3"
              type="button"
              onClick={toggleDrawer}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
