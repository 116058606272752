import "dotenv/config";

class SiteService {
  async getSite(): Promise<any> {
    try {
      const site = JSON.parse(sessionStorage.getItem("site") || "{}");
      if (site.config) {
        return site;
      } else {
        const response = await fetch(
          `${process.env.REACT_APP_API_HOST}/site/0`
        );
        const site = await response.json();
        sessionStorage.setItem("site", JSON.stringify(site));
        return site;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async updateSite(token: string, data: any): Promise<Record<string, any>> {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/site/0`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      return response.json();
    } catch (error) {
      console.log(error);
      return { error };
    }
  }
}

const siteService = new SiteService();
export default siteService;
