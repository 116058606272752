import { useContext, useEffect, useState } from "react";
import { UserContext } from "../App";
import orderService from "../services/orders";
import OrderD from "../products/OrderD";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Purchases() {
  const [orders, setOrders] = useState([] as any[]);
  const { user, token } = useContext(UserContext);
  const navigate = useNavigate();
  if (!user.id) {
    navigate("/");
  }

  useEffect(() => {
    const getOrders = async () => {
      if (token) {
        const orders = await orderService.getOrders(token);
        setOrders(orders);
      }
    };
    getOrders();
  }, [token]);

  return (
    <div className="center-form">
      <div className="margin-form">
        {orders.length === 0 && (
          <div className="d-flex flex-column bg-white">
            <h3 className="text-center mb-3">Todavía no tienes compras</h3>
            <div className="d-flex justify-content-center">
              <FontAwesomeIcon icon={["fas", "face-sad-tear"]} size="2x" />
            </div>
          </div>
        )}
        {orders.map((order, i) => (
          <OrderD key={i} order={order} />
        ))}
        <div className="d-flex justify-content-center mt-5">
          <Link to="/products" className="btn btn-outline-dark">
            Ir a la Tienda
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Purchases;
