import Header from "./Header";
import Content from "./Content";
import Footer from "./Footer";
import WhatsAppButton from "../components/WhatsAppButton";

function Template(props: any) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        position: "relative",
      }}
    >
      <Header />
      <Content>{props.children}</Content>
      <WhatsAppButton />
      <Footer />
    </div>
  );
}

export default Template;
