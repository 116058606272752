import { useState } from "react";
import { Pagination } from "react-bootstrap";
import { arrayRange } from "../utils";

function AdvancedPagination(props: any) {
  const [pageRange, setPageRange] = useState({ min: 1, max: 1 });
  const { page } = props;

  function changePage(i: number) {
    props.onChange && props.onChange(i);
  }

  function nextPage() {
    setPageRange({
      min: pageRange.min + 1,
      max: pageRange.max + 1,
    });
    changePage(page + 1 <= props.pageCount ? page + 1 : page);
  }

  function previousPage() {
    setPageRange({
      min: pageRange.min - 1,
      max: pageRange.max - 1,
    });
    changePage(page - 1 >= 1 ? page - 1 : page);
  }

  function renderItems() {
    if (props.pageCount <= 7) {
      return arrayRange(2, props.pageCount).map((i) => (
        <Pagination.Item
          key={i}
          active={page === i}
          onClick={() => {
            changePage(i);
          }}
        >
          {i}
        </Pagination.Item>
      ));
    } else
      return (
        <>
          {page < 5 ? (
            <Pagination.Item
              active={page === 2}
              onClick={() => {
                changePage(2);
              }}
            >
              {2}
            </Pagination.Item>
          ) : (
            <Pagination.Ellipsis disabled />
          )}
          {arrayRange(
            page > 4
              ? page + 2 >= props.pageCount
                ? props.pageCount - 4
                : page - 2
              : 3,
            page > 2
              ? page + 3 < props.pageCount
                ? page + 2
                : props.pageCount - 2
              : 5
          ).map((i) => (
            <Pagination.Item
              key={i}
              active={page === i}
              onClick={() => {
                changePage(i);
              }}
            >
              {i}
            </Pagination.Item>
          ))}
          {page + 3 >= props.pageCount ? (
            <Pagination.Item
              active={page === props.pageCount - 1}
              onClick={() => {
                changePage(props.pageCount - 1);
              }}
            >
              {props.pageCount - 1}
            </Pagination.Item>
          ) : (
            <Pagination.Ellipsis disabled />
          )}
          <Pagination.Item
            active={page === props.pageCount}
            onClick={() => {
              changePage(props.pageCount);
            }}
          >
            {props.pageCount}
          </Pagination.Item>
        </>
      );
  }

  return (
    <nav aria-label="Page navigation example" className="ms-auto">
      <Pagination className="my-0">
        <Pagination.First
          onClick={() => {
            changePage(1);
          }}
        />
        <Pagination.Prev onClick={previousPage} />
        <Pagination.Item
          active={page === 1}
          onClick={() => {
            changePage(1);
          }}
        >
          {1}
        </Pagination.Item>
        {renderItems()}
        <Pagination.Next onClick={nextPage} />
        <Pagination.Last
          onClick={() => {
            changePage(props.pageCount);
          }}
        />
      </Pagination>
    </nav>
  );
}

export default AdvancedPagination;
