import "dotenv/config";
import { ApiListResponseDto } from "../dtos";

class TypeService {
  async getTypes(): Promise<ApiListResponseDto> {
    try {
      const types = JSON.parse(sessionStorage.getItem("types") || "{}");
      if (types.total > 0) {
        return types;
      } else {
        const response = await fetch(
          `${process.env.REACT_APP_API_HOST}/types/`
        );
        const types = await response.json();
        sessionStorage.setItem("types", JSON.stringify(types));
        return types;
      }
    } catch (error) {
      console.log(error);
      return new ApiListResponseDto();
    }
  }
}

const typeService = new TypeService();
export default typeService;
