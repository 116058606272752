import "dotenv/config";
import { ApiListResponseDto } from "../dtos";
import { toast } from "react-toastify";

class FileService {
  async getFiles(limit: number, page: number): Promise<ApiListResponseDto> {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/files?limit=${limit}&page=${page}`
      );
      return response.json();
    } catch (error) {
      console.log(error);
      return new ApiListResponseDto();
    }
  }

  async updateBanner(
    token: string,
    id: number,
    file: File
  ): Promise<Record<string, any>> {
    try {
      const formData = new FormData();
      formData.append("files", file);
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/files/update-banner/${id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      const jsonResponse = await response.json();
      if (jsonResponse.status === 400) {
        toast.error(jsonResponse.message);
      }
      return jsonResponse;
    } catch (error) {
      console.log(error);
      return { error };
    }
  }

  async uploadFiles(
    token: string,
    files: File[]
  ): Promise<Record<string, any>> {
    try {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("files", file);
      });
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/files/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      const jsonResponse = await response.json();
      if (jsonResponse.status === 400) {
        toast.error(jsonResponse.message);
      }
      return jsonResponse;
    } catch (error) {
      console.log(error);
      return { error };
    }
  }

  async removeFile(token: string, id: number): Promise<Record<string, any>> {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/files/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.json();
    } catch (error) {
      console.log(error);
      return { error };
    }
  }
}

const fileService = new FileService();
export default fileService;
