import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { ApiListResponseDto } from "../dtos";
import { UserContext } from "../App";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import fileService from "../services/files";
import AdvancedPagination from "./AdvancedPagination";
import { Img } from "react-image";

const PER_PAGE = 8;

function SelectImageModal(props: any) {
  const { setUser, token, setToken } = useContext(UserContext);
  const [modalShow, setModalShow] = useState(false);
  const [page, setPage] = useState(1);
  const [files, setFiles] = useState(new ApiListResponseDto());
  const [filesToUpload, setFilesToUpload] = useState([] as File[]);
  const { product, setProduct } = props;
  let selected = props.selected || [];
  const navigate = useNavigate();

  const onConfirm = () => {
    props.onConfirm && props.onConfirm();
  };

  const onCancel = () => {
    props.onCancel && props.onCancel();
  };

  useEffect(() => {
    getFiles(page);
  }, [page]);

  const getFiles = async (page: number) => {
    const files = await fileService.getFiles(PER_PAGE, page);
    setFiles(files);
  };

  const logout = () => {
    localStorage.setItem("access_token", "");
    setToken("");
    setUser({});
    toast.error("Tu sesión ha expirado");
    navigate("/user/login");
  };

  const uploadFiles = async () => {
    const response = await fileService.uploadFiles(token, filesToUpload);
    if (response.statusCode === 401) {
      logout();
    } else {
      setPage(1);
    }
  };

  const removeFile = async (id: number) => {
    const response = await fileService.removeFile(token, id);
    if (response.statusCode === 401) {
      logout();
    } else {
      setPage(1);
    }
  };

  const popoverLeft = (id: number) => (
    <Popover id="popover-positioned-left" data-trigger="focus">
      <button
        className="btn btn-outline-dark py-2"
        onClick={() => removeFile(id)}
      >
        Confirmar Eliminación
      </button>
    </Popover>
  );

  const modal = (
    <Modal
      show={modalShow}
      onHide={() => {
        setModalShow(false);
        onCancel();
      }}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      unmountOnClose
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Selecciona las imágenes
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col">
          <div id="image-container" className="card shadow-sm">
            <div className="row g-0" style={{ height: "550px" }}>
              {files.data.length > 0 &&
                files.data.map((file: any, i: number) => {
                  return (
                    <div
                      className="col-3 p-2"
                      key={i}
                      style={{
                        display: "flex",
                        position: "relative",
                        justifyContent: "right",
                        height: "260px",
                      }}
                    >
                      <Img
                        className={`rounded mb-2 ratio`}
                        style={{
                          border:
                            file.path === product.img ||
                            selected.includes(file.path)
                              ? "solid"
                              : "",
                          borderColor:
                            file.path === product.img
                              ? "green"
                              : selected.includes(file.path)
                              ? "orange"
                              : "",
                          height: "260px",
                        }}
                        loader={
                          <div
                            className="spinner-border"
                            style={{ alignSelf: "center" }}
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        }
                        container={(children) => {
                          return (
                            <div>
                              {children}
                              <OverlayTrigger
                                trigger="focus"
                                placement="left"
                                overlay={popoverLeft(file.id)}
                              >
                                <button
                                  className="btn btn-outline-dark py-2 m-2"
                                  title="Eliminar Imagen"
                                  style={{
                                    position: "absolute",
                                    right: "10px",
                                  }}
                                >
                                  <FontAwesomeIcon icon={["fas", "trash"]} />
                                </button>
                              </OverlayTrigger>
                            </div>
                          );
                        }}
                        alt={file.name}
                        src={file.path || "/assets/img/sin-imagen.jpg"}
                        onClick={() => {
                          if (selected.includes(file.path)) {
                            selected = selected.filter(
                              (path: any) => path !== file.path
                            );
                          } else {
                            selected.push(file.path);
                          }
                          const img = selected.shift() || null;
                          const gallery = selected;
                          setProduct({ ...product, img, gallery });
                        }}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div style={{ marginRight: "auto" }}>
          <input
            className="btn btn-outline-dark py-2"
            style={{ marginRight: "10px" }}
            type="file"
            id="formFileMultiple"
            multiple
            onChange={(e) => {
              const fileList =
                (e.target.files && Array.from(e.target.files)) || [];
              const prefixedFileList = fileList.map((file) => {
                const timestamp = Date.now();
                const prefixedName = `${timestamp}_${file.name}`;
                return new File([file], prefixedName, { type: file.type });
              });
              setFilesToUpload(prefixedFileList);
            }}
          ></input>
          <button
            className="btn btn-outline-dark py-2 p-2"
            disabled={filesToUpload.length === 0}
            onClick={() => {
              let element: any =
                document && document.getElementById("formFileMultiple");
              element.value = "";
              element.files = null;
              setFilesToUpload([]);
              uploadFiles();
            }}
          >
            Cargar
          </button>
        </div>

        <div className="d-flex align-items-center mt-auto">
          <span className="text-muted small p-2">
            Viendo {(files.page - 1) * PER_PAGE + (files.total > 0 ? 1 : 0)} al{" "}
            {(files.page - 1) * PER_PAGE + files.count} - Total {files.total}
          </span>

          <AdvancedPagination
            onChange={setPage}
            page={page}
            pageCount={files.pageCount}
          />

          <div className="ps-4">
            <button
              className="btn btn-outline-dark py-2"
              onClick={() => {
                onConfirm();
                setModalShow(false);
              }}
            >
              Guardar
            </button>

            <button
              className="btn btn-outline-dark py-2"
              onClick={() => {
                onCancel();
                setModalShow(false);
              }}
            >
              Cancelar
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );

  return (
    <div>
      <button
        className="btn btn-outline-dark py-2 w-100"
        title="Agregar Imágenes"
        onClick={() => setModalShow(true)}
      >
        <FontAwesomeIcon icon={["fas", "plus"]} size="2x" />
      </button>

      {modal}
    </div>
  );
}

export default SelectImageModal;
