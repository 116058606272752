import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="center-form">
      <div className="margin-form">
        <div>
          La url ingresada no existe
          <div className="d-flex justify-content-center mt-3">
            <Link to="/products" className="btn btn-outline-dark">
              Ir a la Tienda
            </Link>
            <Link to="/cart" className="btn btn-outline-dark ms-2">
              Ir al Carrito
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
