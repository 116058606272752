import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../App";
import { useContext } from "react";
import { Img } from "react-image";
// import { Card, Row, Col } from "react-bootstrap"; //TODO: use bootstrap class

function ProductH(props: any) {
  const { addToCart, manageMode } = useContext(UserContext);

  const price = props.price;
  let percentOff;
  let offPrice: any = price ? `$ ${price.toFixed(2)}` : "Producto sin Precio";

  if (price && props.percentOff && props.percentOff > 0) {
    percentOff = (
      <div
        className="badge bg-dim py-2 text-white position-absolute"
        style={{ top: "0.5rem", left: "0.5rem" }}
      >
        {props.percentOff}% Descuento
      </div>
    );

    offPrice = (
      <>
        <del>$ {price.toFixed(2)}</del>
        {" $ "}
        {(price - (props.percentOff * price) / 100).toFixed(2)}
      </>
    );
  }
  return (
    <div className="col">
      <div
        className={`card shadow-sm${
          props.stock === 0 ? " bg-custom-light" : ""
        }`}
      >
        <div className="row g-0">
          <div className="col-4" style={{ height: "200px" }}>
            <Link to={`/products/${props.id}`}>
              {percentOff}
              <Img
                className="rounded-start bg-dark cover w-100 h-100"
                alt={props.name}
                src={props.img || "/assets/img/sin-imagen.jpg"}
                loader={
                  <div style={{ height: "200px" }}>
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                }
              />
              {!props.isAvailable && (
                <div
                  style={{
                    position: "absolute",
                    top: 201,
                    width: "48%",
                    borderBottom: "2px solid rgb(255, 0, 0)",
                    transform: "rotate(-45deg)",
                    transformOrigin: "0 0",
                  }}
                />
              )}
            </Link>
          </div>
          <div className="col-8">
            <div className="card-body h-100">
              <div className="d-flex flex-column h-100">
                <h5 className="card-title text-dark text-truncate mb-1">
                  {props.name || "Producto sin Nombre"}
                </h5>
                <span className="card-text text-muted mb-2 flex-shrink-0">
                  {offPrice}
                </span>
                <div className="mt-auto d-flex">
                  {!manageMode ? (
                    <button
                      className="btn btn-outline-dark ms-auto"
                      onClick={() => addToCart(props)}
                      disabled={props && (!props.price || !props.stock)}
                    >
                      <FontAwesomeIcon icon={["fas", "cart-plus"]} /> Agregar al
                      carrito
                    </button>
                  ) : (
                    <Link
                      className="btn btn-outline-dark ms-auto"
                      to={`/products/${props.id}`}
                    >
                      Editar
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductH;
