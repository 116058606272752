import Template from "./template/Template";
import ProductDetail from "./products/detail/ProductDetail";
import { Route, Routes, useNavigate } from "react-router-dom";
import Cart from "./components/Cart";
import Landing from "./landing/Landing";
import Login from "./components/Login";
import ProductList from "./products/ProductList";
import Purchases from "./components/Purchases";
import Stock from "./components/Stock";
import React, { useEffect, useState } from "react";
import NotFound from "./components/400";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { verify } from "jsonwebtoken";
import moment from "moment";
import siteService from "./services/site";
import AboutPage from "./components/AboutPage";
import ReturnPolicyPage from "./components/ReturnPolicyPage";
import FAQsPage from "./components/FAQsPage";

export const UserContext = React.createContext(null as any);

function App() {
  const [cart, setCart] = useState([] as any[]);
  const [user, setUser] = useState({} as any);
  const [site, setSite] = useState({} as any);
  const [token, setToken] = useState("");
  const [manageMode, setManageMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const context = {
    user,
    setUser,
    cart,
    setCart,
    addToCart,
    removeToCart,
    token,
    setToken,
    manageMode,
    setManageMode,
    site,
  };

  useEffect(() => {
    const getSite = async () => {
      const site = await siteService.getSite();
      setSite(site);
      setLoading(false);
    };
    !site.config && getSite();
  }, [site]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("cart") || "[]");
    if (items) {
      setCart(items);
    }
  }, [setCart]);

  useEffect(() => {
    const logout = () => {
      localStorage.setItem("access_token", "");
      setToken("");
      setUser({});
      toast.error("Tu sesión ha expirado");
      navigate("/user/login");
    };

    const token = localStorage.getItem("access_token") || "";
    var decoded: any;
    if (token) {
      try {
        const jwtPublicKey = `${process.env.REACT_APP_JWT_PUBLIC_KEY}`;
        decoded = verify(token, jwtPublicKey);
        if (decoded && decoded.exp && moment(decoded.exp * 1000) <= moment()) {
          logout();
        }
        setUser({
          id: decoded.id,
          name: decoded.name,
          lastname: decoded.lastname,
          email: decoded.email,
          phone: decoded.phone,
          isAdmin: decoded.isAdmin,
        });
        setToken(token);
      } catch (error) {
        logout();
      }
    }
  }, [setToken, navigate]);

  function addToCart(product: any) {
    const searched = cart.find((p) => p.id === product.id) || {
      ...product,
      cant: 0,
    };
    const cant =
      searched.cant >= product.stock ? product.stock : searched.cant + 1;
    const newCart = [
      ...cart.filter((p) => p.id !== product.id),
      {
        ...searched,
        cant,
        subtotal: product.price * (searched.cant + 1),
        subtotalT:
          (product.price +
            (product.category.cardTaxPercentage * product.price) / 100) *
          (searched.cant + 1),
        discount:
          (product.percentOff / 100) * product.price * (searched.cant + 1),
      },
    ].sort((a, b) => a.id - b.id);
    setCart(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart));
  }

  function removeToCart(product: any, remove = false) {
    const searched = cart.find((p) => p.id === product.id);
    let newCart = cart.filter((p) => p.id !== product.id);
    if (searched.cant - 1 > 0 && !remove) {
      newCart.push({
        ...searched,
        cant: searched.cant - 1,
        subtotal: product.price * (searched.cant - 1),
        subtotalT:
          (product.price +
            (product.category.cardTaxPercentage * product.price) / 100) *
          (searched.cant - 1),
        discount:
          (product.percentOff / 100) * product.price * (searched.cant - 1),
      });
    }
    setCart(newCart.sort((a, b) => a.id - b.id));
    localStorage.setItem("cart", JSON.stringify(newCart));
  }

  return (
    <UserContext.Provider value={context}>
      {loading ? (
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <>
          <ToastContainer />
          <Template>
            <Routes>
              <Route path="/user/:mode" element={<Login />} />
              <Route path="/products" element={<ProductList />} />
              <Route
                path="/products/category/:slug"
                element={<ProductList />}
              />
              <Route path="/products/:productId" element={<ProductDetail />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/my_purchases" element={<Purchases />} />
              <Route path="/stock" element={<Stock />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/return-policy" element={<ReturnPolicyPage />} />
              <Route path="/FAQs" element={<FAQsPage />} />
              <Route path="/" element={<Landing />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Template>
        </>
      )}
    </UserContext.Provider>
  );
}

export default App;
