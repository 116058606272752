import { useState } from "react";
import { Modal } from "react-bootstrap";
import HtmlEditor from "./HtmlEditor";

function TransferModal(props: any) {
  const [modalShow, setModalShow] = useState(false);

  const onConfirm = () => {
    props.onConfirm && props.onConfirm();
  };

  const onCancel = () => {
    props.onCancel && props.onCancel();
  };

  const modal = (
    <Modal
      show={modalShow}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Trasferencia
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <HtmlEditor configName={"transferInstructions"} readOnly />
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-outline-dark py-2"
          onClick={() => {
            onConfirm();
            setModalShow(false);
          }}
        >
          Confirmar
        </button>

        <button
          className="btn btn-outline-dark py-2"
          onClick={() => {
            onCancel();
            setModalShow(false);
          }}
        >
          Cancelar
        </button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <div>
      <button
        className="btn btn-outline-dark py-2 w-100"
        title="Confirmar compra"
        onClick={() => setModalShow(true)}
      >
        C O N F I R M A R &nbsp; C O M P R A
      </button>

      {modal}
    </div>
  );
}

export default TransferModal;
