import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import ProductP from "../products/ProductP";
import ProductSelect from "./ProductSelect";

function PurchaseModal(props: any) {
  const [cart, setCart] = useState([] as any[]);
  const [modalShow, setModalShow] = useState(false);

  const onConfirm = () => {
    setCart([]);
    props.onConfirm && props.onConfirm(cart);
  };

  const onCancel = () => {
    setCart([]);
    props.onCancel && props.onCancel();
  };

  function addToCart(product: any) {
    const searched = cart.find((p) => p.id === product.id) || {
      ...product,
      cant: 0,
    };
    const cant = searched.cant + 1;
    const newCart = [
      ...cart.filter((p) => p.id !== product.id),
      {
        ...searched,
        cant,
      },
    ];
    setCart(newCart.sort((a, b) => a.id - b.id));
  }

  function removeToCart(product: any, remove = false) {
    const searched = cart.find((p) => p.id === product.id);
    let newCart = cart.filter((p) => p.id !== product.id);
    if (searched.cant - 1 > 0 && !remove) {
      newCart.push({
        ...searched,
        cant: searched.cant - 1,
      });
    }
    setCart(newCart.sort((a, b) => a.id - b.id));
  }

  const onProductSelect = (selected: any) => {
    selected && addToCart(selected);
  };

  const modal = (
    <Modal
      show={modalShow}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Nuevo Ingreso
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProductSelect onChange={onProductSelect} />
        {cart.length > 0 && (
          <div className="container mt-5 py-4 px-xl-5">
            <div className="row mb-3">
              <div className={"col-md-8"}>
                <h2 style={{ textAlign: "center" }}>Productos</h2>
                <div
                  className={
                    "row row-cols-1 row-cols-md-2 row-cols-lg-2 g-3 mb-4 flex-shrink-0 row-cols-xl-1"
                  }
                >
                  {cart.map((p: any, i: number) => (
                    <ProductP
                      key={i}
                      addToCart={addToCart}
                      removeToCart={removeToCart}
                      editable
                      {...p}
                    />
                  ))}
                </div>
              </div>
              <div className={"col-md-4"}>
                <h2 style={{ textAlign: "center" }}>Detalle</h2>
                <div className="mt-3">
                  <h4>Se actualizara el stock de {cart.length} productos.</h4>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-outline-dark py-2"
          onClick={() => {
            onConfirm();
            setModalShow(false);
          }}
          disabled={cart.length === 0}
        >
          Guardar
        </button>

        <button
          className="btn btn-outline-dark py-2"
          onClick={() => {
            onCancel();
            setModalShow(false);
          }}
        >
          Cancelar
        </button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <div>
      <button
        className="btn btn-outline-dark py-2 w-100"
        title="Nuevo ingreso"
        onClick={() => setModalShow(true)}
      >
        <FontAwesomeIcon icon={["fas", "boxes-packing"]} size="2x" /> Nuevo
        ingreso
      </button>

      {modal}
    </div>
  );
}

export default PurchaseModal;
