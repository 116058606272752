import { useContext, useEffect, useState } from "react";
import { UserContext } from "../App";
import { useNavigate } from "react-router-dom";
import orderService from "../services/orders";
import OrderS from "../products/OrderS";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SaleModal from "./SaleModal";
import PurchaseModal from "./PurchaseModal";
import { toast } from "react-toastify";

function Stock() {
  const [orders, setOrders] = useState([] as any[]);
  const { user, token } = useContext(UserContext);
  const navigate = useNavigate();
  if (!user.isAdmin) {
    navigate("/");
  }

  useEffect(() => {
    const getSales = async () => {
      if (token) {
        const orders = await orderService.getSales(token);
        setOrders(orders);
      }
    };
    getSales();
  }, [token]);

  const newPurchase = (cart: any) => {
    const newPurchase = async () => {
      if (token) {
        const purchase = await orderService.newPurchase(token, { cart });
        if (purchase.cart.length !== cart.length) {
          toast.warn(`No se pudieron actualizar todos los productos.`);
        } else {
          toast.success(`Todos los productos fueron actualizados.`);
        }
      }
    };
    newPurchase();
  };

  const deleteOrder = async (id: number) => {
    await orderService.deleteOrder(token, id);
    const newOrders = orders.filter((o) => o.id !== id);
    setOrders(newOrders);
  };

  const confirmTransfer = async (id: number) => {
    await orderService.confirmTransfer(token, id);
    const newOrders = orders.map((o) =>
      o.id !== id ? o : { ...o, status: "confirmed" }
    );
    setOrders(newOrders);
  };

  const newSale = (cart: any, paymentMethod: string) => {
    const newSale = async () => {
      if (token) {
        const order = await orderService.newSale(token, {
          cart,
          paymentMethod,
        });
        if (!order.id) {
          toast.warn(`Venta vacía, revise stock.`);
        } else {
          setOrders([...orders, order]);
        }
      }
    };
    newSale();
  };

  return (
    <div className="center-form">
      <div className="margin-form">
        <div className="d-flex" style={{ justifyContent: "space-between" }}>
          <PurchaseModal onConfirm={newPurchase} />
          <SaleModal onConfirm={newSale} />
        </div>
        {orders.length === 0 ? (
          <div className="d-flex flex-column bg-white">
            <h3 className="text-center mb-3">Todavía no tienes ventas</h3>
            <div className="d-flex justify-content-center">
              <FontAwesomeIcon icon={["fas", "face-sad-tear"]} size="2x" />
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <h2>Ventas</h2>
          </div>
        )}
        {orders.map((order, i) => (
          <OrderS
            key={i}
            order={order}
            onDelete={deleteOrder}
            onConfirm={confirmTransfer}
          />
        ))}
      </div>
    </div>
  );
}

export default Stock;
