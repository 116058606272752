import "dotenv/config";

class OrderService {
  async getOrder(token: string, id: string): Promise<Record<string, any>> {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/orders/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.json();
    } catch (error) {
      console.log(error);
      return { error };
    }
  }

  async deleteOrder(token: string, id: number): Promise<Record<string, any>> {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/orders/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.json();
    } catch (error) {
      console.log(error);
      return { error };
    }
  }

  async getOrders(token: string): Promise<any[]> {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/orders/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.json();
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  async getSales(token: string): Promise<any[]> {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/orders/finished/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.json();
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  async createOrder(token: string, body: any): Promise<Record<string, any>> {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/orders/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      return response.json();
    } catch (error) {
      console.log(error);
      return { error };
    }
  }

  async orderToTransferred(
    token: string,
    body: any
  ): Promise<Record<string, any>> {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/orders/`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      return response.json();
    } catch (error) {
      console.log(error);
      return { error };
    }
  }

  async confirmTransfer(
    token: string,
    id: number
  ): Promise<Record<string, any>> {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/orders/${id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.json();
    } catch (error) {
      console.log(error);
      return { error };
    }
  }

  async newPurchase(token: string, body: any): Promise<Record<string, any>> {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/orders/purchase/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      return response.json();
    } catch (error) {
      console.log(error);
      return { error };
    }
  }

  async newSale(token: string, body: any): Promise<Record<string, any>> {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/orders/sale/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      return response.json();
    } catch (error) {
      console.log(error);
      return { error };
    }
  }
}

const orderService = new OrderService();
export default orderService;
