export const arrayRange = (start: number, stop: number, step = 1) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
  );

export const getRandomNumber = (
  minimum: number,
  maximum: number,
  digits = 2
) => {
  return +(Math.random() * (maximum - minimum + 1) + minimum).toFixed(digits);
};

export const getRandomInteger = (minimum: number, maximum: number) => {
  return Math.floor(getRandomNumber(minimum, maximum));
};

export const getRandomBoolean = () => {
  return Math.random() >= 0.5;
};

export const choiceOne = (list: any[]) => {
  return list[getRandomInteger(0, list.length - 1)];
};
