import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../App";
import { useContext } from "react";
import { Img } from "react-image";
// import { Card, Col } from "react-bootstrap"; //TODO: use bootstrap class

function Product(props: any) {
  const { addToCart, manageMode } = useContext(UserContext);

  const price = props.price;
  let percentOff;
  let offPrice: any = price ? `$ ${price.toFixed(2)}` : "Producto sin Precio";

  if (price && props.percentOff && props.percentOff > 0) {
    percentOff = (
      <div
        className="badge bg-dim py-2 text-white position-absolute"
        style={{ top: "0.5rem", right: "0.5rem" }}
      >
        {props.percentOff}% Descuento
      </div>
    );

    offPrice = (
      <>
        <del>$ {price.toFixed(2)}</del>
        {" $ "}
        {(price - (props.percentOff * price) / 100).toFixed(2)}
      </>
    );
  }

  return (
    <div className="col" style={{ height: "345" }}>
      <div
        className={`card shadow-sm${
          props.stock === 0 ? " bg-custom-light" : ""
        }`}
      >
        <Link to={`/products/${props.id}`}>
          {percentOff}
          <Img
            className="card-img-top bg-dark cover"
            height="200"
            alt={props.name}
            src={props.img || "/assets/img/sin-imagen.jpg"}
            loader={
              <div style={{ height: "200px" }}>
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            }
          />
          {!props.isAvailable && (
            <div
              style={{
                position: "absolute",
                top: 201,
                width: "98%",
                borderBottom: "2px solid rgb(255, 0, 0)",
                transform: "rotate(-45deg)",
                transformOrigin: "0 0",
              }}
            />
          )}
        </Link>
        <div className="card-body">
          <h5 className="card-title text-center text-dark text-truncate">
            {props.name || "Producto sin Nombre"}
          </h5>
          <p className="card-text text-center text-muted mb-0">{offPrice}</p>
          <div className="d-grid d-block">
            {!manageMode ? (
              <button
                className="btn btn-outline-dark mt-3"
                onClick={() => addToCart(props)}
                disabled={props && (!props.price || !props.stock)}
              >
                <FontAwesomeIcon icon={["fas", "cart-plus"]} /> Agregar al
                carrito
              </button>
            ) : (
              <Link
                className="btn btn-outline-dark mt-3"
                to={`/products/${props.id}`}
              >
                Editar
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Product;
