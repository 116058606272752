function Footer() {
  return (
    <footer id="footer" className="mt-auto py-5">
      <div className="container d-flex justify-content-center">
        <span className="text-white">Copyright &copy; Clementiina</span>
      </div>
    </footer>
  );
}

export default Footer;
