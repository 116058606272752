import { useContext, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { UserContext } from "../App";
import { toast } from "react-toastify";
import siteService from "../services/site";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function HtmlEditor(props: any) {
  const { token, site, manageMode } = useContext(UserContext);
  const config = site?.config && site.config[props.configName];
  const readOnly = props.readOnly || !manageMode;

  const [content, setContent] = useState({
    entityMap: {},
    blocks: [],
    ...config,
  });

  const updateSite = async () => {
    site.config[props.configName] = content;
    const response = await siteService.updateSite(token, site);
    if (!response) {
      toast.warn("No se pudo guardar.");
    } else {
      sessionStorage.setItem("site", JSON.stringify(site));
      window.location.reload();
    }
  };

  return (
    <div>
      <Editor
        locale="es"
        initialContentState={content}
        onContentStateChange={(contentState: any) => {
          setContent(contentState);
        }}
        readOnly={readOnly}
        toolbarHidden={readOnly}
      />
      {manageMode && (
        <button
          className="btn btn-outline-dark"
          type="submit"
          onClick={() => {
            updateSite();
          }}
        >
          Guardar
        </button>
      )}
    </div>
  );
}

export default HtmlEditor;
