import "dotenv/config";
import { ApiListResponseDto } from "../dtos";

class BrandService {
  async getBrands(): Promise<ApiListResponseDto> {
    try {
      const brands = JSON.parse(sessionStorage.getItem("brands") || "{}");
      if (brands.total > 0) {
        return brands;
      } else {
        const response = await fetch(
          `${process.env.REACT_APP_API_HOST}/brands/`
        );
        const brands = await response.json();
        sessionStorage.setItem("brands", JSON.stringify(brands));
        return brands;
      }
    } catch (error) {
      console.log(error);
      return new ApiListResponseDto();
    }
  }
}

const brandService = new BrandService();
export default brandService;
