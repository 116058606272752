import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Img } from "react-image";
// import { Card, Row, Col } from "react-bootstrap"; //TODO: use bootstrap class

function ProductP(props: any) {
  const addToCart = (product: any) => {
    props.addToCart && props.addToCart(product);
  };

  const removeToCart = (product: any, remove = false) => {
    props.removeToCart && props.removeToCart(product, remove);
  };

  const editable = props.editable;
  return (
    <div className="col">
      <div className="row g-0">
        <div className="col-4">
          <Link to={`/products/${props.id}`}>
            <Img
              className="rounded-start bg-dark cover w-100 h-100"
              alt={props.name}
              src={props.img || "/assets/img/sin-imagen.jpg"}
              loader={
                <div style={{ height: "200px" }}>
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              }
            />
          </Link>
        </div>
        <div className="col-8">
          <div className="card-body h-100">
            <div className="mt-auto d-flex">
              <button
                className="btn btn-outline-dark ms-auto"
                onClick={() => editable && removeToCart(props, true)}
                disabled={!editable}
              >
                {<FontAwesomeIcon icon={["fas", "x"]} />}
              </button>
            </div>
            <div className="d-flex flex-column h-100">
              <h5 className="card-title text-dark text-truncate mb-1">
                <button
                  className="btn btn btn-light btn-sm rounded-pill"
                  onClick={() => editable && removeToCart(props)}
                  disabled={!editable || props.cant <= 1}
                >
                  {<FontAwesomeIcon icon={["fas", "minus"]} />}
                </button>
                <span className="badge rounded-pill bg-dark">{props.cant}</span>
                <button
                  className="btn btn btn-light btn-sm rounded-pill"
                  onClick={() => editable && addToCart(props)}
                  disabled={!editable}
                >
                  {<FontAwesomeIcon icon={["fas", "add"]} />}
                </button>
                {props.name || "Producto sin Nombre"}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductP;
