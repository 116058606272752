import { UserContext } from "../App";
import { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { OverlayTrigger, Popover } from "react-bootstrap";

function WhatsAppButton() {
  const { site } = useContext(UserContext);
  const WHATSAPP = site?.config?.social?.whatsapp;

  const [showPopover, setShowPopover] = useState(false);

  const handleClick = () => {
    setShowPopover(true);
  };

  const handlePopoverClick = () => {
    setShowPopover(false);
    window.open(WHATSAPP, "_blank");
  };

  const popover = (
    <Popover
      id="whatsapp-popover"
      className="webchat-popover"
      onMouseLeave={() => setShowPopover(false)}
    >
      <Popover.Header as="h3">Chatear con nosotros</Popover.Header>
      <Popover.Body
        className="btn btn-outline-dark border-light"
        onClick={handlePopoverClick}
      >
        Haz clic para abrir WhatsApp
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="top"
      show={showPopover}
      overlay={popover}
    >
      <div className="webchat-button" onClick={handleClick}>
        <FontAwesomeIcon icon={faWhatsapp} size="2x" />
      </div>
    </OverlayTrigger>
  );
}

export default WhatsAppButton;
