import "dotenv/config";
import { ApiListResponseDto } from "../dtos";

class CategoryService {
  async getCategories(): Promise<ApiListResponseDto> {
    try {
      const categories = JSON.parse(
        sessionStorage.getItem("categories") || "{}"
      );
      if (categories.total > 0) {
        return categories;
      } else {
        const response = await fetch(
          `${process.env.REACT_APP_API_HOST}/categories/`
        );
        const categories = await response.json();
        sessionStorage.setItem("categories", JSON.stringify(categories));
        return categories;
      }
    } catch (error) {
      console.log(error);
      return new ApiListResponseDto();
    }
  }
}

const categoryService = new CategoryService();
export default categoryService;
