import { Link, useParams } from "react-router-dom";
import Product from "./Product";
import ProductH from "./ProductH";
import { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScrollToTopOnMount from "../template/ScrollToTopOnMount";
import productService from "../services/products";
import brandService from "../services/brands";
import categoryService from "../services/categories";
import typeService from "../services/types";
import { ApiListResponseDto } from "../dtos";
import { UserContext } from "../App";
import AdvancedPagination from "../components/AdvancedPagination";

//import { ListGroup, ListGroupItem, Col } from "react-bootstrap"; //TODO: use bootstrap class

const LIMIT = 12;

function FilterMenuLeft(props: any) {
  const [minPrice, setMinPrice] = props.minPrice;
  const [maxPrice, setMaxPrice] = props.maxPrice;

  const params = useParams();
  const categories = props.categories as ApiListResponseDto;
  const brands = props.brands as ApiListResponseDto;
  const types = props.types as ApiListResponseDto;

  function applyFilter(e: any) {
    e.preventDefault();
    props.setFilter({ ...props.filter, minPrice, maxPrice });
  }

  return (
    <ul className="list-group list-group-flush rounded">
      <li className="list-group-item d-none d-lg-block">
        <h5 className="mt-1 mb-2">Categorías</h5>
        <div className="d-flex flex-wrap my-2">
          {categories.data.length > 0 &&
            categories.data.map((c: any, i: number) => {
              return (
                <Link
                  key={i}
                  to={
                    props.filter.category !== c
                      ? `/products/category/${c.slug}`
                      : `/products`
                  }
                  className={`btn btn-sm btn-outline-dark rounded-pill me-2 mb-2${
                    c.slug === params.slug ? " active" : ""
                  }`}
                  onClick={() => {
                    if (props.filter.category === c) {
                      props.setFilter({ ...props.filter, category: null });
                    } else {
                      props.setFilter({ ...props.filter, category: c });
                    }
                  }}
                >
                  {c.name}
                </Link>
              );
            })}
        </div>
      </li>
      <li className="list-group-item">
        <h5 className="mt-1 mb-1">Marcas</h5>
        <div className="d-flex flex-column">
          {brands.data.length > 0 &&
            brands.data.map((b: any, i: number) => {
              return (
                <div key={i} className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => {
                      const brand = props.filter.brands.find(
                        (f: any) => f.id === b.id
                      );
                      const brands = brand
                        ? props.filter.brands.filter((f: any) => f.id !== b.id)
                        : props.filter.brands.concat(b);
                      props.setFilter({ ...props.filter, brands: brands });
                      b.selected = !b.selected;
                    }}
                    checked={b.selected}
                    defaultChecked={b.selected}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    {b.name}
                  </label>
                </div>
              );
            })}
        </div>
      </li>
      <li className="list-group-item">
        <h5 className="mt-1 mb-1">Tipos</h5>
        <div className="d-flex flex-column">
          {types.data.length > 0 &&
            types.data.map((t: any, i: number) => {
              return (
                <div key={i} className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => {
                      const type = props.filter.types.find(
                        (f: any) => f.id === t.id
                      );
                      const types = type
                        ? props.filter.types.filter((f: any) => f.id !== t.id)
                        : props.filter.types.concat(t);
                      props.setFilter({ ...props.filter, types: types });
                      t.selected = !t.selected;
                    }}
                    checked={t.selected}
                    defaultChecked={t.selected}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    {t.name}
                  </label>
                </div>
              );
            })}
        </div>
      </li>
      <li className="list-group-item">
        <h5 className="mt-1 mb-2">Rango de Precios</h5>
        <form id="price-range" onSubmit={applyFilter}>
          <div>
            <input
              id="minPrice"
              type="number"
              className="form-control"
              name="minPrice"
              step="500"
              min="0"
              value={minPrice}
              onChange={(e) => setMinPrice(+e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && applyFilter(e)}
            />
            <label htmlFor="minPrice">Precio mínimo:</label>
            <span className="validity"></span>
          </div>
          <div className="mt-2">
            <input
              id="maxPrice"
              type="number"
              className="form-control"
              name="maxPrice"
              step="500"
              min={minPrice && maxPrice ? minPrice + 500 : 0}
              value={maxPrice}
              onChange={(e) => setMaxPrice(+e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && applyFilter(e)}
            />
            <label htmlFor="maxPrice">Precio máximo:</label>
            <span className="validity"></span>
          </div>
          <div>
            <input
              type="submit"
              className="btn btn-outline-dark mt-2"
              value="Aplicar Rango"
            />
          </div>
        </form>
      </li>
    </ul>
  );
}

function ProductList() {
  const params = useParams();
  const [viewType, setViewType] = useState({ grid: true });
  const [products, setProducts] = useState(new ApiListResponseDto());
  const [brands, setBrands] = useState(new ApiListResponseDto());
  const [categories, setCategories] = useState(new ApiListResponseDto());
  const [category, setCategory] = useState(null as any);
  const [types, setTypes] = useState(new ApiListResponseDto());
  const [filter, setFilter] = useState({
    category: null as any,
    brands: [] as any[],
    types: [] as any[],
    minPrice: 0,
    maxPrice: 0,
  });
  const [order, setOrder] = useState("name,ASC");
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const { token, manageMode } = useContext(UserContext);

  useEffect(() => {
    if (categories && params.slug) {
      const category = categories.data.find((c: any) => c.slug === params.slug);
      setCategory(category);
    } else {
      setCategory(null);
    }
  }, [categories, params.slug]);

  useEffect(() => {
    const getProducts = async () => {
      const products = await productService.getProducts(
        token,
        LIMIT,
        page,
        order,
        search,
        filter
      );
      setProducts(products);
    };
    getProducts();
  }, [token, page, order, search, filter]);

  useEffect(() => {
    const getBrands = async () => {
      const brands = await brandService.getBrands();
      setBrands(brands);
    };
    !brands.total && getBrands();
  }, [brands.total]);

  useEffect(() => {
    const getCategories = async () => {
      const categories = await categoryService.getCategories();
      setCategories(categories);
    };
    !categories.total && getCategories();
  }, [categories.total]);

  useEffect(() => {
    const getTypes = async () => {
      const types = await typeService.getTypes();
      setTypes(types);
    };
    !types.total && getTypes();
  }, [types.total]);

  function changeViewType() {
    setViewType({
      grid: !viewType.grid,
    });
  }

  function scrollToTop() {
    window.location.href = "#";
  }

  function changePage(i: number) {
    setPage(i);
    scrollToTop();
  }

  function clear() {
    setFilter({
      category: null as any,
      brands: [] as any[],
      types: [] as any[],
      minPrice: 0,
      maxPrice: 0,
    });
    setCategory(null);
    setOrder("name,ASC");
    setSearch("");
    setSearchText("");
    setPage(1);
    setMinPrice(0);
    setMaxPrice(0);

    const brandUnSelected = {
      ...brands,
      data: brands.data.map((b) => ({ ...b, selected: undefined })),
    };
    setBrands(brandUnSelected);

    const typeUnSelected = {
      ...types,
      data: types.data.map((t) => ({ ...t, selected: undefined })),
    };
    setTypes(typeUnSelected);
  }

  function applyFilter(filter: {
    category: any;
    brands: any[];
    types: any[];
    minPrice: number;
    maxPrice: number;
  }) {
    setFilter(filter);
    changePage(1);
  }

  return (
    <div className="container mt-5 py-4 px-xl-5">
      <ScrollToTopOnMount />
      <nav
        aria-label="breadcrumb"
        className="bg-custom-light rounded"
        style={{ display: "flex" }}
      >
        <ol className="breadcrumb p-3 mb-0">
          <li className="breadcrumb-item">
            <Link
              className="text-decoration-none link-secondary"
              to="/products"
              onClick={clear}
            >
              Todos los Productos
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {(category && category.name) || ""}
          </li>
        </ol>
        {manageMode && (
          <Link
            className="btn btn-outline-dark py-2 "
            title="Agregar Producto"
            style={{
              justifyContent: "flex-end",
              marginLeft: "auto",
              marginTop: "0.5rem",
              marginRight: "1rem",
              height: "40px",
            }}
            to="/products/0"
          >
            <FontAwesomeIcon icon={["fas", "plus"]} />
          </Link>
        )}
      </nav>

      <div className="h-scroller d-block d-lg-none">
        <nav className="nav h-underline">
          {categories.data.length > 0 &&
            categories.data.map((c, i) => {
              return (
                <div key={i} className="h-link me-2">
                  <Link
                    to={`/products/category/${c.slug}`}
                    className="btn btn-sm btn-outline-dark rounded-pill"
                  >
                    {c.name}
                  </Link>
                </div>
              );
            })}
        </nav>
      </div>

      <div className="row mb-3 d-block d-lg-none">
        <div className="col-12">
          <div id="accordionFilter" className="accordion shadow-sm">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button fw-bold collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFilter"
                  aria-expanded="false"
                  aria-controls="collapseFilter"
                >
                  Filtrar Productos
                </button>
              </h2>
            </div>
            <div
              id="collapseFilter"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionFilter"
            >
              <div className="accordion-body p-0">
                <FilterMenuLeft
                  filter={filter}
                  setFilter={applyFilter}
                  brands={brands}
                  categories={categories}
                  category={category}
                  types={types}
                  maxPrice={[maxPrice, setMaxPrice]}
                  minPrice={[minPrice, setMinPrice]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-4 mt-lg-3">
        <div id="filter" className="d-none d-lg-block col-lg-3">
          <div className="border rounded shadow-sm">
            <FilterMenuLeft
              filter={filter}
              setFilter={applyFilter}
              brands={brands}
              categories={categories}
              category={category}
              types={types}
              maxPrice={[maxPrice, setMaxPrice]}
              minPrice={[minPrice, setMinPrice]}
            />
          </div>
        </div>
        <div className="col-lg-9">
          <div className="d-flex flex-column h-100">
            <div className="row mb-3">
              <div className="col-lg-3 d-lg-block">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={order}
                  onChange={(e: any) => {
                    setOrder(e.target.value);
                  }}
                >
                  <option value="name,ASC">Ordenar</option>
                  <option value="price,ASC">Menor precio</option>
                  <option value="price,DESC">Mayor precio</option>
                  <option value="rating,DESC">Rating</option>
                </select>
                <br className="d-lg-none" />
              </div>
              <div className="col-lg-9 col-xl-5 offset-xl-4 d-flex flex-row">
                <div className="input-group">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Buscar producto  ..."
                    aria-label="search input"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    onKeyDown={(e) =>
                      e.key === "Enter" && setSearch(searchText)
                    }
                  />
                  <button
                    className="btn btn-outline-dark"
                    onClick={() => setSearch(searchText)}
                  >
                    <FontAwesomeIcon icon={["fas", "search"]} />
                  </button>
                </div>
                <button
                  className="btn btn-outline-dark ms-2 d-none d-lg-inline"
                  onClick={changeViewType}
                >
                  <FontAwesomeIcon
                    icon={["fas", viewType.grid ? "th-list" : "th-large"]}
                  />
                </button>
              </div>
            </div>
            <div
              className={
                "row row-cols-1 row-cols-md-2 row-cols-lg-2 g-3 mb-4 flex-shrink-0 " +
                (viewType.grid ? "row-cols-xl-3" : "row-cols-xl-2")
              }
            >
              {products.data.length > 0 &&
                products.data.map((p: any, i: number) =>
                  viewType.grid ? (
                    <Product key={i} {...p} />
                  ) : (
                    <ProductH key={i} {...p} />
                  )
                )}
            </div>
            <div className="d-flex align-items-center mt-auto">
              <span className="text-muted small d-none d-md-inline">
                Viendo{" "}
                {(products.page - 1) * LIMIT + (products.total > 0 ? 1 : 0)} al{" "}
                {(products.page - 1) * LIMIT + products.count} - Total{" "}
                {products.total}
              </span>
              <nav aria-label="Page navigation example" className="ms-auto">
                <AdvancedPagination
                  onChange={changePage}
                  page={page}
                  pageCount={products.pageCount}
                />
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductList;
