import RelatedProduct from "./RelatedProduct";
import { Link, useParams, useNavigate } from "react-router-dom";
import ScrollToTopOnMount from "../../template/ScrollToTopOnMount";
import ReactStars from "react-stars";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../App";
import productService from "../../services/products";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { ApiListResponseDto } from "../../dtos";
import brandService from "../../services/brands";
import categoryService from "../../services/categories";
import typeService from "../../services/types";
import SelectImageModal from "../../components/SelectImageModal";
import { FormCheck } from "react-bootstrap"; //TODO: use bootstrap class Card, Row, Col
import { Img } from "react-image";

function ProductDetail() {
  const params = useParams();
  const [product, setProduct] = useState({
    category: {},
    brand: {},
    type: {},
    rating: 0,
  } as any);
  const [initialProduct, setInitialProduct] = useState({
    category: {},
    brand: {},
    type: {},
    rating: 0,
  } as any);
  const [brands, setBrands] = useState(new ApiListResponseDto());
  const [categories, setCategories] = useState(new ApiListResponseDto());
  const [types, setTypes] = useState(new ApiListResponseDto());
  const [relaters, setRelaters] = useState([] as any[]);
  const [selected, setSelected] = useState(0);
  const [breaks, setBreaks] = useState(0);
  const {
    addToCart,
    manageMode,
    setManageMode,
    token,
    setToken,
    user,
    setUser,
  } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const id = +(params.productId || 0);
    const getProduct = async () => {
      if (id) {
        const product = await productService.getProduct(id);
        getRelatedProducts();
        setProduct({
          ...product,
          category: product.category || {},
          brand: product.brand || {},
          type: product.type || {},
        });
        setInitialProduct({
          ...product,
          category: product.category || {},
          brand: product.brand || {},
          type: product.type || {},
        });
        const matches = product.description.match(/\n/g);
        setBreaks(
          Math.round(product.description.length / 40) +
            (matches && matches.length) || 0
        );
      }
    };
    const getRelatedProducts = async () => {
      const relaters = await productService.getRelatedProducts(id);
      setRelaters(relaters);
    };
    getProduct();
  }, [params.productId]);

  useEffect(() => {
    if (manageMode) {
      const getBrands = async () => {
        const brands = await brandService.getBrands();
        setBrands(brands);
      };
      !brands.total && getBrands();
    }
  }, [manageMode, brands.total]);

  useEffect(() => {
    if (manageMode) {
      const getCategories = async () => {
        const categories = await categoryService.getCategories();
        setCategories(categories);
      };
      !categories.total && getCategories();
    }
  }, [manageMode, categories.total]);

  useEffect(() => {
    if (manageMode) {
      const getTypes = async () => {
        const types = await typeService.getTypes();
        setTypes(types);
      };
      !types.total && getTypes();
    }
  }, [manageMode, types.total]);

  const changeRating = (rating: number) => {
    setProduct({ ...product, rating });
  };

  const validate = () => {
    let result = true;
    if (!product.code) {
      result = false;
      toast.error("El código del producto es requerido");
    }
    if (!product.name) {
      result = false;
      toast.error("El nombre del producto es requerido");
    }
    if (!product.description) {
      result = false;
      toast.error("La descripción del producto es requerida");
    }
    return result;
  };

  const logout = () => {
    localStorage.setItem("access_token", "");
    setToken("");
    setUser({});
    toast.error("Tu sesión ha expirado");
    navigate("/user/login");
  };

  const createOrUpdateProduct = async () => {
    if (validate()) {
      let response;
      if (!product.id) {
        response = await productService.createProduct(token, product);
      } else {
        response = await productService.updateProduct(token, product);
      }
      if (!response || response.statusCode === 401) {
        logout();
      } else {
        navigate(`/products/${response.id}`);
      }
      setManageMode(false);
    }
  };

  if (!product.isAvailable && user.id && !user.isAdmin) {
    navigate("/");
  }

  const price = +product.price;
  let percentOff;
  let offPrice: any =
    (price && `$ ${price.toFixed(2)}`) || "Producto sin precio";

  if (product.percentOff && product.percentOff > 0) {
    percentOff = (
      <div
        className="badge bg-dim py-2 text-white position-absolute"
        style={{ top: "0.5rem", right: "0.5rem", zIndex: 1 }}
      >
        {product.percentOff}% Descuento
      </div>
    );
    offPrice = (
      <>
        <del>$ {price.toFixed(2)}</del>
        {" $ "}
        {(price - (product.percentOff * price) / 100).toFixed(2)}
      </>
    );
  }

  return (
    <div className="container mt-5 py-4 px-xl-5">
      <ScrollToTopOnMount />
      <nav aria-label="breadcrumb" className="bg-custom-light rounded mb-4">
        <ol className="breadcrumb p-3">
          <li className="breadcrumb-item">
            <Link
              className="text-decoration-none link-secondary"
              to="/products"
            >
              Todos los Productos
            </Link>
          </li>
          {product.category.slug && (
            <li className="breadcrumb-item">
              <a
                className="text-decoration-none link-secondary"
                href={`category/${product.category.slug}` || "!#"}
              >
                {product.category.name || "Producto sin Categoría"}
              </a>
            </li>
          )}
          <li className="breadcrumb-item active" aria-current="page">
            {product.name || "Producto sin Nombre"}
          </li>
        </ol>
      </nav>
      <div className="row mb-4">
        <div className="d-none d-lg-block col-lg-1">
          <div className="image-vertical-scroller">
            <div className="d-flex flex-column">
              {
                <Img
                  className={`rounded mb-2 ratio`}
                  style={{ border: 0 === selected ? "solid green" : "" }}
                  alt={product.name}
                  src={product.img || "/assets/img/sin-imagen.jpg"}
                  onClick={() => setSelected(0)}
                  height={60}
                  loader={
                    <div style={{ height: "60px" }}>
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  }
                />
              }
              {product.gallery &&
                product.gallery.length > 0 &&
                product.gallery.map((img: any, i: number) => {
                  return (
                    <Img
                      key={i}
                      className={`rounded mb-2 ratio`}
                      style={{
                        border: i === selected - 1 ? "solid orange" : "",
                      }}
                      alt={product.name}
                      src={img || "/assets/img/sin-imagen.jpg"}
                      onClick={() => setSelected(i + 1)}
                      height={60}
                      loader={
                        <div style={{ height: "60px" }}>
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      }
                    />
                  );
                })}
            </div>
            <div className="d-flex flex-column">
              {manageMode && (
                <SelectImageModal
                  selected={
                    product.img
                      ? [product.img, ...product.gallery]
                      : product.gallery
                  }
                  product={product}
                  setProduct={setProduct}
                  onCancel={() => {
                    setProduct(initialProduct);
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="row">
            <div className="col-12 mb-4">
              <div className="card shadow-sm">
                {percentOff}
                {manageMode && (
                  <div
                    className="badge text-white position-absolute"
                    style={{
                      top: product.percentOff > 0 ? "2.5rem" : "0.5rem",
                      right: "0rem",
                      zIndex: 1,
                    }}
                  >
                    {product.gallery &&
                      ((product.gallery.length > 0 && selected > 0) ||
                        (product.gallery.length === 0 && product.img)) && (
                        <button
                          className="btn btn-outline-dark py-2 w-100"
                          title="Quitar Imagen"
                          onClick={() => {
                            if (selected === 0) {
                              setProduct({ ...product, img: null });
                            } else {
                              const gallery = product.gallery.filter(
                                (p: any, i: number) => i !== selected - 1
                              );
                              setProduct({ ...product, gallery });
                              setSelected(0);
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={["fas", "trash"]} />
                        </button>
                      )}
                  </div>
                )}
                <Img
                  className="border rounded ratio ratio-1x1"
                  alt={product.name}
                  src={
                    selected === 0
                      ? product.img || "/assets/img/sin-imagen.jpg"
                      : (product.gallery && product.gallery[selected - 1]) ||
                        "/assets/img/sin-imagen.jpg"
                  }
                  height={600}
                  loader={
                    <div style={{ minHeight: "600px" }}>
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-5">
          <div className="d-flex flex-column h-100">
            <h2 className="mb-1">
              {!manageMode ? (
                product.name || "Producto sin Nombre"
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <label>Nombre</label>
                    <span data-toggle="tooltip" title="Oculto / Visible">
                      <FormCheck
                        style={{ marginTop: "-4px" }}
                        type="switch"
                        value={product.isAvailable}
                        defaultChecked={product.isAvailable}
                        onChange={(e) => {
                          const isAvailable = e.target.checked;
                          setProduct({ ...product, isAvailable });
                        }}
                      />
                    </span>
                  </div>
                  <input
                    style={{ width: "-webkit-fill-available" }}
                    className={!product.name ? "border-danger" : ""}
                    type="text"
                    value={product.name || ""}
                    onChange={(e) => {
                      const name = e.target.value;
                      setProduct({ ...product, name });
                    }}
                  />
                </div>
              )}
            </h2>
            <h4 className="text-muted mb-4">
              {!manageMode ? (
                offPrice
              ) : (
                <div>
                  <div>
                    <label>Precio</label>
                    <input
                      style={{ width: "-webkit-fill-available" }}
                      type="number"
                      value={product.price || 0}
                      onChange={(e) => {
                        e.target.value = `${
                          Math.trunc(+e.target.value * 100) / 100
                        }`;
                        const price = e.target.value;
                        setProduct({ ...product, price });
                      }}
                    />
                  </div>
                  <div>
                    <label>Descuento (%)</label>
                    <input
                      style={{ width: "-webkit-fill-available" }}
                      type="number"
                      value={product.percentOff || 0}
                      onChange={(e) => {
                        e.target.value = `${
                          Math.trunc(+e.target.value * 100) / 100
                        }`;
                        const percentOff = e.target.value;
                        setProduct({ ...product, percentOff });
                      }}
                    />
                  </div>
                </div>
              )}
            </h4>

            <div className="row g-3 mb-4">
              <div className="col">
                {!manageMode ? (
                  <button
                    className="btn btn-outline-dark py-2 w-100"
                    onClick={() => addToCart(product)}
                    disabled={!product.price || !product.stock}
                  >
                    <FontAwesomeIcon icon={["fas", "cart-plus"]} /> Agregar al
                    carrito
                  </button>
                ) : (
                  <button
                    className="btn btn-outline-dark py-2 w-100"
                    onClick={() => createOrUpdateProduct()}
                  >
                    <FontAwesomeIcon icon={["fas", "save"]} />{" "}
                    {product.id ? "Guardar" : "Crear"}
                  </button>
                )}
              </div>
            </div>

            <h4 className="mb-0">Características</h4>
            <hr />
            <dl className="row">
              <dt className="col-sm-4">Código</dt>
              <dd className="col-sm-8 mb-3">
                {!manageMode ? (
                  product.code || "Producto sin Código"
                ) : (
                  <input
                    style={{ width: "-webkit-fill-available" }}
                    className={!product.code ? "border-danger" : ""}
                    type="text"
                    value={product.code || ""}
                    onChange={(e) => {
                      const code = e.target.value;
                      setProduct({ ...product, code });
                    }}
                  />
                )}
              </dd>

              <dt className="col-sm-4">Categoría</dt>
              <dd className="col-sm-8 mb-3">
                {!manageMode ? (
                  product.category.name || "Producto sin Categoría"
                ) : (
                  <select
                    className="form-select"
                    onChange={(e: any) => {
                      const category = JSON.parse(e.target.value);
                      setProduct({ ...product, category });
                    }}
                    value={JSON.stringify(product.category)}
                  >
                    <option key={0} value={"{}"}>
                      Sin Categoría
                    </option>
                    {categories.data.length > 0 &&
                      categories.data.map((c: any, i: number) => (
                        <option key={i + 1} value={JSON.stringify(c)}>
                          {c.name}
                        </option>
                      ))}
                  </select>
                )}
              </dd>

              <dt className="col-sm-4">Marca</dt>
              <dd className="col-sm-8 mb-3">
                {!manageMode ? (
                  product.brand.name || "Producto sin Marca"
                ) : (
                  <select
                    className="form-select"
                    onChange={(e: any) => {
                      const brand = JSON.parse(e.target.value);
                      setProduct({ ...product, brand });
                    }}
                    value={JSON.stringify(product.brand)}
                  >
                    <option key={0} value={"{}"}>
                      Sin Marca
                    </option>
                    {brands.data.length > 0 &&
                      brands.data.map((b: any, i: number) => (
                        <option key={i} value={JSON.stringify(b)}>
                          {b.name}
                        </option>
                      ))}
                  </select>
                )}
              </dd>

              <dt className="col-sm-4">Tipo</dt>
              <dd className="col-sm-8 mb-3">
                {!manageMode ? (
                  product.type.name || "Producto sin Tipo"
                ) : (
                  <select
                    className="form-select"
                    onChange={(e: any) => {
                      const type = JSON.parse(e.target.value);
                      setProduct({ ...product, type });
                    }}
                    value={JSON.stringify(product.type)}
                  >
                    <option key={0} value={"{}"}>
                      Sin Tipo
                    </option>
                    {types.data.length > 0 &&
                      types.data.map((t: any, i: number) => (
                        <option key={i} value={JSON.stringify(t)}>
                          {t.name}
                        </option>
                      ))}
                  </select>
                )}
              </dd>

              <dt className="col-sm-4">Stock</dt>
              <dd className="col-sm-8 mb-3">
                {!manageMode ? (
                  product.stock || "Producto sin Stock"
                ) : (
                  <input
                    style={{ width: "-webkit-fill-available" }}
                    type="number"
                    value={product.stock || 0}
                    onChange={(e) => {
                      e.target.value = `${Math.trunc(+e.target.value)}`;
                      const stock = e.target.value;
                      setProduct({ ...product, stock });
                    }}
                  />
                )}
              </dd>

              <dt className="col-sm-4">Rating</dt>
              <dd className="col-sm-8 mb-3">
                {manageMode && (
                  <ReactStars
                    edit={true}
                    value={product.rating}
                    onChange={changeRating}
                    size={30}
                  />
                )}
                {!manageMode && (
                  <ReactStars
                    edit={false}
                    value={product.rating}
                    onChange={changeRating}
                    size={30}
                  />
                )}
              </dd>
            </dl>

            <h4 className="mb-0">Descripción</h4>
            <hr />
            <p className="lead flex-shrink-0">
              {!manageMode ? (
                <small>
                  {product.description || "Producto sin Descripción"}
                </small>
              ) : (
                <textarea
                  style={{ width: "-webkit-fill-available" }}
                  className={!product.description ? "border-danger" : ""}
                  value={product.description || ""}
                  rows={breaks}
                  onChange={(e) => {
                    const description = e.target.value;
                    setProduct({ ...product, description });
                  }}
                />
              )}
            </p>
          </div>
        </div>
      </div>

      {relaters.length > 0 && (
        <div className="row">
          <div className="col-md-12 mb-4">
            <hr />
            <h4 className="text-muted my-4">Productos relacionados</h4>
            <div className="row row-cols-1 row-cols-md-3 row-cols-lg-4 g-3 px-md-5">
              {relaters.map((r, i) => {
                return <RelatedProduct key={i} {...r} />;
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductDetail;
