import { useEffect } from "react";
import { useLocation, useNavigationType } from "react-router-dom";

function ScrollToTopOnMount() {
  const location = useLocation();
  const navType = useNavigationType();
  useEffect(() => {
    if (navType !== "POP") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [location, navType]);
  return <></>;
}

export default ScrollToTopOnMount;
