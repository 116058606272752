import HtmlEditor from "./HtmlEditor";

function FAQsPage() {
  return (
    <div className="m-4 mt-5 pt-5">
      <HtmlEditor configName={"FAQsPage"} />
    </div>
  );
}

export default FAQsPage;
